/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'emergency-medical-treatment',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Emergency Medical Treatment",
        meta: [{
          name: 'description',
          content: "A plan of coverage for the treatment of any emergency injuries or illnesses that resulted during your trip. All G1G standalone policies offer this coverage in one form or another. Emergency means any unforeseen illness or injury that may cause a person to seek immediate treatment for fear of death or permanent bodily damage."
        }, {
          property: 'og:description',
          content: "A plan of coverage for the treatment of any emergency injuries or illnesses that resulted during your trip. All G1G standalone policies offer this coverage in one form or another. Emergency means any unforeseen illness or injury that may cause a person to seek immediate treatment for fear of death or permanent bodily damage."
        }, {
          property: 'og:title',
          content: "Emergency Medical Treatment"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/emergency-medical-treatment`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/emergency-medical-treatment`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Emergency Medical Treatment | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "A plan of coverage for the treatment of any emergency injuries or illnesses that resulted during your trip. All G1G standalone policies offer this coverage in one form or another. Emergency means any unforeseen illness or injury that may cause a person to seek immediate treatment for fear of death or permanent bodily damage."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/emergency-medical-treatment`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Emergency Medical Treatment\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"A plan of coverage for the treatment of any emergency injuries or illnesses that resulted during your trip. All G1G standalone policies offer this coverage in one form or another. Emergency means any unforeseen illness or injury that may cause a person to seek immediate treatment for fear of death or permanent bodily damage.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Emergency Medical Treatment</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">A plan of coverage for the treatment of any emergency injuries or illnesses that resulted during your trip. All G1G standalone policies offer this coverage in one form or another. Emergency means any unforeseen illness or injury that may cause a person to seek immediate treatment for fear of death or permanent bodily damage.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};