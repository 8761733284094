/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'provider-directorynetwork',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Provider Directory/Network",
        meta: [{
          name: 'description',
          content: "The provider directory is a database of the hospital network, clinics, treatment facilities, doctors, and other care providers that a particular plan. Particularly relevant to Premium Coverage Plans (also known as Comprehensive Coverage Plans), provider networks offer discounts or waivers of coinsurance and co-pay costs. Common discounts are 90%-10% splits of the coinsurance (with the insurance company paying 90%, and the remaining 10% being left to the insured) or in some cases a complete waiver of the co-pay as a whole. Provider directories are closely related to PPO plans (Preferred Provider Organization). PPOs allow for a wider range of doctors to choose from, allowing the insured to stay within its provider directory (again with the incentives of waivers and discounts) or seek treatment from any doctor (but without any discounts or waivers). PPOs also allow patients to see a specialist without a referral from a Primary Care Physician. Direct access to specialists is good for people who have chronic illness, or should there be a need for urgent care."
        }, {
          property: 'og:description',
          content: "The provider directory is a database of the hospital network, clinics, treatment facilities, doctors, and other care providers that a particular plan. Particularly relevant to Premium Coverage Plans (also known as Comprehensive Coverage Plans), provider networks offer discounts or waivers of coinsurance and co-pay costs. Common discounts are 90%-10% splits of the coinsurance (with the insurance company paying 90%, and the remaining 10% being left to the insured) or in some cases a complete waiver of the co-pay as a whole. Provider directories are closely related to PPO plans (Preferred Provider Organization). PPOs allow for a wider range of doctors to choose from, allowing the insured to stay within its provider directory (again with the incentives of waivers and discounts) or seek treatment from any doctor (but without any discounts or waivers). PPOs also allow patients to see a specialist without a referral from a Primary Care Physician. Direct access to specialists is good for people who have chronic illness, or should there be a need for urgent care."
        }, {
          property: 'og:title',
          content: "Provider Directory/Network"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/provider-directorynetwork`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/provider-directorynetwork`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Provider Directory/Network | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "The provider directory is a database of the hospital network, clinics, treatment facilities, doctors, and other care providers that a particular plan. Particularly relevant to Premium Coverage Plans (also known as Comprehensive Coverage Plans), provider networks offer discounts or waivers of coinsurance and co-pay costs. Common discounts are 90%-10% splits of the coinsurance (with the insurance company paying 90%, and the remaining 10% being left to the insured) or in some cases a complete waiver of the co-pay as a whole. Provider directories are closely related to PPO plans (Preferred Provider Organization). PPOs allow for a wider range of doctors to choose from, allowing the insured to stay within its provider directory (again with the incentives of waivers and discounts) or seek treatment from any doctor (but without any discounts or waivers). PPOs also allow patients to see a specialist without a referral from a Primary Care Physician. Direct access to specialists is good for people who have chronic illness, or should there be a need for urgent care."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/provider-directorynetwork`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Provider Directory/Network\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"The provider directory is a database of the hospital network, clinics, treatment facilities, doctors, and other care providers that a particular plan. Particularly relevant to Premium Coverage Plans (also known as Comprehensive Coverage Plans), provider networks offer discounts or waivers of coinsurance and co-pay costs. Common discounts are 90%-10% splits of the coinsurance (with the insurance company paying 90%, and the remaining 10% being left to the insured) or in some cases a complete waiver of the co-pay as a whole. Provider directories are closely related to PPO plans (Preferred Provider Organization). PPOs allow for a wider range of doctors to choose from, allowing the insured to stay within its provider directory (again with the incentives of waivers and discounts) or seek treatment from any doctor (but without any discounts or waivers). PPOs also allow patients to see a specialist without a referral from a Primary Care Physician. Direct access to specialists is good for people who have chronic illness, or should there be a need for urgent care.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Provider Directory/Network</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">The provider directory is a database of the hospital network, clinics, treatment facilities, doctors, and other care providers that a particular plan. Particularly relevant to Premium Coverage Plans (also known as Comprehensive Coverage Plans), provider networks offer discounts or waivers of coinsurance and co-pay costs. Common discounts are 90%-10% splits of the coinsurance (with the insurance company paying 90%, and the remaining 10% being left to the insured) or in some cases a complete waiver of the co-pay as a whole. Provider directories are closely related to PPO plans (Preferred Provider Organization). PPOs allow for a wider range of doctors to choose from, allowing the insured to stay within its provider directory (again with the incentives of waivers and discounts) or seek treatment from any doctor (but without any discounts or waivers). PPOs also allow patients to see a specialist without a referral from a Primary Care Physician. Direct access to specialists is good for people who have chronic illness, or should there be a need for urgent care.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};