/* unplugin-vue-components disabled */import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "main-body pb-5"
};
const _hoisted_2 = {
  class: "claim-user mt-5 mb-4"
};
const _hoisted_3 = {
  class: "row mt-5"
};
const _hoisted_4 = {
  class: "col-md-5"
};
const _hoisted_5 = {
  class: "row mt-5"
};
const _hoisted_6 = {
  class: "col-md-5"
};
const _hoisted_7 = {
  class: "row mt-5"
};
const _hoisted_8 = {
  class: "col-md-5"
};
const _hoisted_9 = {
  class: "form-group"
};
const _hoisted_10 = {
  class: "row mt-5"
};
const _hoisted_11 = {
  class: "col-md-12"
};
const _hoisted_12 = ["error"];
const _hoisted_13 = {
  class: "media-card-section mt-5"
};
import Layout from "@/components/claims/layout/claimsLayout.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatVueCelenderPicker, formatVueCelenderRange } from "@/helpers/dateFormat";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { missedConnectionInformation, claimsMissedConnectionInformationValidation, missedConnectionInformationRules, TripCostPaymentDatesValidation } from "@/composable/vuelidate";
import moment from "moment";
import Input from "@/components/input.vue";
import { toRaw } from "@vue/reactivity";
import Textarea from "@/components/textarea.vue";
export default {
  __name: 'MissedConnectionInformation',
  setup(__props) {
    const {
      useActions,
      useState
    } = createNamespacedHelpers("claims");
    const {
      createClaim,
      claimsInfo
    } = useState(["createClaim", "claimsInfo"]);
    const {
      setMissedConnectionForm
    } = useActions(["setMissedConnectionForm"]);
    const breakpoints = useBreakpoints(breakpointsTailwind);
    console.log(toRaw(claimsMissedConnectionInformationValidation.value));
    let setTC = async () => {
      let check = await claimsMissedConnectionInformationValidation.value.$validate();
      console.log('check', check);
      if (check) {
        const {
          selectedType,
          types
        } = toRaw(claimsInfo.value);
        let findClaimType = types.find(({
          slug
        }) => slug == selectedType.slug);
        console.log('travelDelayInformation', toRaw(missedConnectionInformation));
        /* let finalType = types.map((type) =>
           type.id === mergeFormAndType.id ? mergeFormAndType : type
         );*/

        const rawMissedConnectionInformation = toRaw(missedConnectionInformation);
        setMissedConnectionForm({
          ...rawMissedConnectionInformation
        });
      }
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(Layout, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[12] || (_cache[12] = _createElementVNode("div", {
          class: "d-flex justify-content-between"
        }, [_createElementVNode("h4", {
          class: "mb-0"
        }, "Missed Connection Information ")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[6] || (_cache[6] = _createElementVNode("h5", {
          class: "mb-4"
        }, "What was the date of missed connection:", -1)), _createElementVNode("div", {
          class: _normalizeClass(["calender-input-wrapper", {
            active: _unref(missedConnectionInformation).missed_connection_date
          }])
        }, [_createElementVNode("span", {
          class: _normalizeClass(["label", {
            active: _unref(missedConnectionInformation).missed_connection_date
          }])
        }, "Date", 2), _createVNode(_unref(Datepicker), {
          modelValue: _unref(missedConnectionInformation).missed_connection_date,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(missedConnectionInformation).missed_connection_date = $event),
          ref: "depatureDateRef",
          class: "date-of-claim",
          autoApply: "",
          format: _unref(formatVueCelenderPicker)
        }, {
          "input-icon": _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("span", {
            class: "material-symbols-outlined"
          }, " today ", -1)])),
          _: 1
        }, 8, ["modelValue", "format"])], 2)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_cache[8] || (_cache[8] = _createElementVNode("h5", {
          class: "mb-4"
        }, " When did the missed connection end: ", -1)), _createElementVNode("div", {
          class: _normalizeClass(["calender-input-wrapper", {
            active: _unref(missedConnectionInformation).missed_connection_end
          }])
        }, [_createElementVNode("span", {
          class: _normalizeClass(["label", {
            active: _unref(missedConnectionInformation).missed_connection_end
          }])
        }, "Date", 2), _createVNode(_unref(Datepicker), {
          modelValue: _unref(missedConnectionInformation).missed_connection_end,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(missedConnectionInformation).missed_connection_end = $event),
          ref: "depatureDateRef",
          class: "date-of-claim",
          autoApply: "",
          format: _unref(formatVueCelenderPicker)
        }, {
          "input-icon": _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("span", {
            class: "material-symbols-outlined"
          }, " today ", -1)])),
          _: 1
        }, 8, ["modelValue", "format"])], 2)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[9] || (_cache[9] = _createElementVNode("h5", {
          class: "mb-4"
        }, "How long was the connection missed by:", -1)), _createElementVNode("div", _hoisted_9, [_createVNode(Input, {
          label: "Delay Duration",
          type: "text",
          icon: "timer",
          modelValue: _unref(missedConnectionInformation).how_long_missed_connection,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(missedConnectionInformation).how_long_missed_connection = $event),
          value: _unref(missedConnectionInformation).how_long_missed_connection,
          class: _normalizeClass({
            error: _unref(claimsMissedConnectionInformationValidation).how_long_missed_connection.$errors.length
          }),
          error: _unref(claimsMissedConnectionInformationValidation).how_long_missed_connection.$errors
        }, null, 8, ["modelValue", "value", "class", "error"])])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_cache[10] || (_cache[10] = _createElementVNode("h5", {
          class: "mb-4"
        }, " Please provide a brief summary by telling us the reason for missing your connection: ", -1)), _withDirectives(_createElementVNode("textarea", {
          class: _normalizeClass(["text-area-claims-details", {
            error: _unref(claimsMissedConnectionInformationValidation).how_long_missed_connection.$errors.length
          }]),
          placeholder: "ENTER TEXT",
          cols: "80",
          rows: "10",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(missedConnectionInformation).summary_for_missed_connection = $event),
          error: _unref(claimsMissedConnectionInformationValidation).how_long_missed_connection.$errors
        }, null, 10, _hoisted_12), [[_vModelText, _unref(missedConnectionInformation).summary_for_missed_connection]])])]), _createVNode(Textarea), _createElementVNode("div", _hoisted_13, [_createElementVNode("button", {
          class: "btn sp-btn btn-primary",
          onClick: _cache[4] || (_cache[4] = (...args) => _unref(setTC) && _unref(setTC)(...args))
        }, _cache[11] || (_cache[11] = [_createTextVNode(" Next "), _createElementVNode("span", {
          class: "material-symbols-outlined"
        }, " chevron_right", -1)]))])])])]),
        _: 1
      });
    };
  }
};