/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'terrorism-coverage',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Terrorism Coverage",
        meta: [{
          name: 'description',
          content: "Coverage in the event your trip is disrupted by an act of terrorism. While terrorism may be a subjective term, it is generally understood as an act, including but not limited to, the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in connection with any organization(s) or government(s) committed for political, religious, ideological or similar purposes including the intention to influence any government and/or to put the public, or any section of the public, in fear. While that surely sounds complicated, we maintain this language not to complicate the issue, rather, to make certain that you are always covered. Generally speaking, it's safe to assume that we will assess each scenario individually for the purpose of inclusion, rather than exclusion; rendering such coverage in your favor. We hope, for the sake of humanity, that this insurance is never used; and yet, we are always prepared in the event that it does."
        }, {
          property: 'og:description',
          content: "Coverage in the event your trip is disrupted by an act of terrorism. While terrorism may be a subjective term, it is generally understood as an act, including but not limited to, the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in connection with any organization(s) or government(s) committed for political, religious, ideological or similar purposes including the intention to influence any government and/or to put the public, or any section of the public, in fear. While that surely sounds complicated, we maintain this language not to complicate the issue, rather, to make certain that you are always covered. Generally speaking, it's safe to assume that we will assess each scenario individually for the purpose of inclusion, rather than exclusion; rendering such coverage in your favor. We hope, for the sake of humanity, that this insurance is never used; and yet, we are always prepared in the event that it does."
        }, {
          property: 'og:title',
          content: "Terrorism Coverage"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/terrorism-coverage`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/terrorism-coverage`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Terrorism Coverage | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Coverage in the event your trip is disrupted by an act of terrorism. While terrorism may be a subjective term, it is generally understood as an act, including but not limited to, the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in connection with any organization(s) or government(s) committed for political, religious, ideological or similar purposes including the intention to influence any government and/or to put the public, or any section of the public, in fear. While that surely sounds complicated, we maintain this language not to complicate the issue, rather, to make certain that you are always covered. Generally speaking, it's safe to assume that we will assess each scenario individually for the purpose of inclusion, rather than exclusion; rendering such coverage in your favor. We hope, for the sake of humanity, that this insurance is never used; and yet, we are always prepared in the event that it does."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/terrorism-coverage`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Terrorism Coverage\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Coverage in the event your trip is disrupted by an act of terrorism. While terrorism may be a subjective term, it is generally understood as an act, including but not limited to, the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in connection with any organization(s) or government(s) committed for political, religious, ideological or similar purposes including the intention to influence any government and/or to put the public, or any section of the public, in fear. While that surely sounds complicated, we maintain this language not to complicate the issue, rather, to make certain that you are always covered. Generally speaking, it's safe to assume that we will assess each scenario individually for the purpose of inclusion, rather than exclusion; rendering such coverage in your favor. We hope, for the sake of humanity, that this insurance is never used; and yet, we are always prepared in the event that it does.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Terrorism Coverage</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Coverage in the event your trip is disrupted by an act of terrorism. While terrorism may be a subjective term, it is generally understood as an act, including but not limited to, the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in connection with any organization(s) or government(s) committed for political, religious, ideological or similar purposes including the intention to influence any government and/or to put the public, or any section of the public, in fear. While that surely sounds complicated, we maintain this language not to complicate the issue, rather, to make certain that you are always covered. Generally speaking, it&#39;s safe to assume that we will assess each scenario individually for the purpose of inclusion, rather than exclusion; rendering such coverage in your favor. We hope, for the sake of humanity, that this insurance is never used; and yet, we are always prepared in the event that it does.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};