/* unplugin-vue-components disabled */import __unplugin_components_0 from '/vercel/path0/src/components/dashboard/layout.vue';
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "edit-sub-details-save"
};
const _hoisted_2 = {
  class: "user-info"
};
const _hoisted_3 = {
  class: "my-3"
};
const _hoisted_4 = {
  class: "row"
};
const _hoisted_5 = {
  class: "form-group"
};
const _hoisted_6 = {
  class: "mt-3"
};
const _hoisted_7 = ["disabled"];
const _hoisted_8 = {
  key: 0,
  class: "spinner-border",
  role: "status"
};
import Input from "@/components/input.vue";
import { editTravelerAge, editTravelerEmail, editTravelerEmailValidation } from "@/composable/vuelidate";
import { notify } from "@kyvg/vue3-notification";
import { useRoute, useRouter } from "vue-router";
import { track } from "@/analytics/events";
export default {
  __name: 'travelDetailsEmail',
  setup(__props) {
    const {
      useActions,
      useState
    } = createNamespacedHelpers("policies");
    const {
      selectedTraveler,
      updateTravelerLoading
    } = useState(["selectedTraveler", "updateTravelerLoading"]);
    let {
      email,
      traveler_uuid
    } = toRaw(selectedTraveler.value);
    editTravelerEmail.traveler_email = email;
    const route = useRoute();
    const router = useRouter();
    const {
      order_no
    } = route.params;
    const {
      updateTravelerAgeResidenceCitizenship
    } = useActions(["updateTravelerAgeResidenceCitizenship"]);
    const loading = computed(() => {
      return updateTravelerLoading.value == "pending" ? true : false;
    });
    const updateTravelsDetails = async () => {
      if (await editTravelerEmailValidation.value.$validate()) {
        await updateTravelerAgeResidenceCitizenship({
          old_email: email,
          email: editTravelerEmail.traveler_email,
          order_id: order_no,
          traveler_uuid
        });
        track("Customer portal - Update Travel Email Details", {
          old_email: email,
          email: editTravelerEmail.traveler_email,
          order_id: order_no,
          traveler_uuid
        });
        notify({
          type: "success",
          title: "Ticket Generated",
          text: "Your traveler email updating request has been submit our cx team will be ping back asp "
        });
        router.go(-1);
      }
    };
    return (_ctx, _cache) => {
      const _component_Layout = __unplugin_components_0;
      return _openBlock(), _createBlock(_component_Layout, null, {
        body: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "heading-edits"
        }, [_createElementVNode("img", {
          alt: "",
          src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/userEdit.svg"
        }), _createElementVNode("h3", null, "Edit traveler details • Email")], -1)), _createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("h4", {
          class: "h4-details"
        }, " Please use your legal Email as it appears on your passport OR banking information. ", -1)), _cache[3] || (_cache[3] = _createElementVNode("h3", {
          class: "title"
        }, "Primary Traveler", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
          class: _normalizeClass([{
            effectStyles: _unref(editTravelerEmail).traveler_email
          }, "col-md-6"])
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(Input, {
          modelValue: _unref(editTravelerEmail).traveler_email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(editTravelerEmail).traveler_email = $event),
          class: _normalizeClass({
            error: _unref(editTravelerEmailValidation).traveler_email.$errors.length
          }),
          error: _unref(editTravelerEmailValidation).traveler_email.$errors,
          value: _unref(editTravelerEmail).traveler_email,
          label: "Email",
          type: "email",
          icon: "mail"
        }, null, 8, ["modelValue", "class", "error", "value"])])], 2), _createElementVNode("div", _hoisted_6, [_createElementVNode("button", {
          class: "btn btn-primary px-4 py-3 mt-md-4 mt-0",
          onClick: updateTravelsDetails,
          disabled: _unref(loading)
        }, [_unref(loading) ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true), _cache[1] || (_cache[1] = _createTextVNode(" Save changes "))], 8, _hoisted_7)])])])])])]),
        _: 1
      });
    };
  }
};