/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'j1-visa-compliant',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "J1 Visa Compliant",
        meta: [{
          name: 'description',
          content: "Student Medical plans are specifically tailored for international students enrolled at an American university, such as those on J1 visas, and for American students studying abroad. Medical insurance is often a requirement for universities and more often than not the policies offered by a university tend to be pricey and limited in their scope of coverage. G1G offers a wide selection of insurance plans for students and at better rates. Many of our plans exceed the benefit requirement levels as stipulated by schools, and are better priced than their university counterparts.J1 visa requirements dictate that compliant policies must have at least $50,000 in total coverage; $7,500 for Repatriation of Remains; A deductible that does not exceed $500 per claim; and a minimum of $10,000 in coverage for Emergency Medical Evacuation."
        }, {
          property: 'og:description',
          content: "Student Medical plans are specifically tailored for international students enrolled at an American university, such as those on J1 visas, and for American students studying abroad. Medical insurance is often a requirement for universities and more often than not the policies offered by a university tend to be pricey and limited in their scope of coverage. G1G offers a wide selection of insurance plans for students and at better rates. Many of our plans exceed the benefit requirement levels as stipulated by schools, and are better priced than their university counterparts.J1 visa requirements dictate that compliant policies must have at least $50,000 in total coverage; $7,500 for Repatriation of Remains; A deductible that does not exceed $500 per claim; and a minimum of $10,000 in coverage for Emergency Medical Evacuation."
        }, {
          property: 'og:title',
          content: "J1 Visa Compliant"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/j1-visa-compliant`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/j1-visa-compliant`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "J1 Visa Compliant | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Student Medical plans are specifically tailored for international students enrolled at an American university, such as those on J1 visas, and for American students studying abroad. Medical insurance is often a requirement for universities and more often than not the policies offered by a university tend to be pricey and limited in their scope of coverage. G1G offers a wide selection of insurance plans for students and at better rates. Many of our plans exceed the benefit requirement levels as stipulated by schools, and are better priced than their university counterparts.J1 visa requirements dictate that compliant policies must have at least $50,000 in total coverage; $7,500 for Repatriation of Remains; A deductible that does not exceed $500 per claim; and a minimum of $10,000 in coverage for Emergency Medical Evacuation."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/j1-visa-compliant`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"J1 Visa Compliant\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Student Medical plans are specifically tailored for international students enrolled at an American university, such as those on J1 visas, and for American students studying abroad. Medical insurance is often a requirement for universities and more often than not the policies offered by a university tend to be pricey and limited in their scope of coverage. G1G offers a wide selection of insurance plans for students and at better rates. Many of our plans exceed the benefit requirement levels as stipulated by schools, and are better priced than their university counterparts.J1 visa requirements dictate that compliant policies must have at least $50,000 in total coverage; $7,500 for Repatriation of Remains; A deductible that does not exceed $500 per claim; and a minimum of $10,000 in coverage for Emergency Medical Evacuation.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">J1 Visa Compliant</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Student Medical plans are specifically tailored for international students enrolled at an American university, such as those on J1 visas, and for American students studying abroad. Medical insurance is often a requirement for universities and more often than not the policies offered by a university tend to be pricey and limited in their scope of coverage. G1G offers a wide selection of insurance plans for students and at better rates. Many of our plans exceed the benefit requirement levels as stipulated by schools, and are better priced than their university counterparts.J1 visa requirements dictate that compliant policies must have at least $50,000 in total coverage; $7,500 for Repatriation of Remains; A deductible that does not exceed $500 per claim; and a minimum of $10,000 in coverage for Emergency Medical Evacuation.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};