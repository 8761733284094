/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "card-grid"
};
const _hoisted_2 = {
  class: "left-grid"
};
const _hoisted_3 = {
  class: "product-name"
};
const _hoisted_4 = {
  class: "right-grid"
};
const _hoisted_5 = {
  class: "plan-price"
};
const _hoisted_6 = {
  class: "card-grid"
};
const _hoisted_7 = {
  class: "left-grid"
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  key: 0,
  class: "buyfor"
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  key: 0,
  class: "addons-wrapper"
};
const _hoisted_12 = {
  key: 0
};
const _hoisted_13 = {
  class: "card-grid align-items-baseline"
};
const _hoisted_14 = {
  class: "left-grid"
};
const _hoisted_15 = {
  key: 0,
  class: "right-grid"
};
const _hoisted_16 = {
  class: "addons-price"
};
const _hoisted_17 = {
  key: 1,
  class: "card-grid align-items-baseline"
};
const _hoisted_18 = {
  class: "right-grid"
};
const _hoisted_19 = {
  class: "addons-price"
};
const _hoisted_20 = {
  class: "card-grid"
};
const _hoisted_21 = {
  class: "left-grid"
};
const _hoisted_22 = {
  class: "travel-dates"
};
const _hoisted_23 = {
  class: "card-grid mt-4"
};
const _hoisted_24 = {
  class: "right-grid"
};
const _hoisted_25 = {
  class: "sub-total mb-0"
};
const _hoisted_26 = {
  class: "action-buttons"
};
import { router } from "@/router";
import { formatVueCelenderPicker } from "@/helpers/dateFormat";
import { priceConverter } from "@/helpers/priceConverter";
import { track } from "@/analytics/events";
import { toRaw } from "vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { itiraStates } from "@/composable/vuelidate";
export default {
  __name: 'cartListCard',
  props: ["cartData", "tripDate"],
  setup(__props) {
    const {
      useActions,
      useState
    } = createNamespacedHelpers("plans");
    const itiraStore = createNamespacedHelpers("itira");
    const {
      itira
    } = itiraStore.useState(["itira"]);
    const userStore = createNamespacedHelpers("user");
    const {
      user
    } = userStore.useState(["user"]);
    let {
      travelers
    } = toRaw(itira.value);
    const {
      allPlans
    } = useState(["allPlans"]);
    const {
      deleteToCartItem
    } = useActions(["deleteToCartItem"]);
    let {
      id
    } = toRaw(__props.cartData);
    let {
      plans
    } = toRaw(allPlans.value);
    let plan = ref(null);
    plan.value = plans.find(({
      PlanId
    }) => PlanId == id);
    let isAvailanleAddons = computed(() => {
      typeof plan.value.extra_add_on == "object" ? true : false;
    });
    let deleteItem = id => {
      let {
        itira_uuid
      } = toRaw(itira.value);
      deleteToCartItem({
        itira_id: itira_uuid,
        item_id: id
      });
      track("Cart Item Deleted", {
        cart_id: itira.value.itira_uuid ?? '',
        item_id: id
      });
    };
    let fixedPrice = price => parseFloat(price).toFixed(2);
    let toAddons = id => {
      router.push({
        path: `/upgrade/${id}`
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h5", _hoisted_3, _toDisplayString(__props.cartData.name), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("h6", _hoisted_5, "$" + _toDisplayString(_unref(priceConverter)(__props.cartData?.price)) + " USD", 1)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [__props.cartData.attributes.travelers?.length ? (_openBlock(), _createElementBlock("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.cartData.attributes.travelers, data => {
        return _openBlock(), _createElementBlock("span", {
          key: data.plan_id
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(travelers), traveler => {
          return _openBlock(), _createElementBlock("span", {
            key: traveler.uuid
          }, [traveler.uuid == data.traveler_uuid ? (_openBlock(), _createElementBlock("span", _hoisted_9, [__props.cartData.attributes.travelers[0].traveler_uuid !== traveler.uuid ? (_openBlock(), _createElementBlock("span", _hoisted_10, ",")) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(data.traveler_with_age), 1)])) : _createCommentVNode("", true)]);
        }), 128))]);
      }), 128))])) : _createCommentVNode("", true)])]), __props.cartData?.attributes?.addons?.length || __props.cartData.attributes.trip_side_assistance ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_cache[3] || (_cache[3] = _createElementVNode("h6", {
        class: "heading"
      }, "Upgrades", -1)), __props.cartData?.attributes?.addons?.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.cartData.attributes.addons, addons => {
        return _openBlock(), _createElementBlock("div", {
          key: addons.id
        }, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("p", null, _toDisplayString(addons.name) + "  ", 1)]), addons?.price > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("h6", _hoisted_16, "+$ " + _toDisplayString(_unref(priceConverter)(addons?.price)) + " USD", 1)])) : _createCommentVNode("", true)])]);
      }), 128))])) : _createCommentVNode("", true), __props.cartData.attributes.trip_side_assistance ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "left-grid"
      }, [_createElementVNode("p", null, "Tripside Assistance  ")], -1)), _createElementVNode("div", _hoisted_18, [_createElementVNode("h6", _hoisted_19, " +$ " + _toDisplayString(_unref(fixedPrice)(__props.cartData.attributes.trip_side_assistance.price)) + " USD ", 1)])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("h6", _hoisted_22, " Dates : " + _toDisplayString(_unref(formatVueCelenderPicker)(__props.tripDate.departure_date)) + " - " + _toDisplayString(_unref(formatVueCelenderPicker)(__props.tripDate.arrival_date)), 1)])]), _createElementVNode("div", _hoisted_23, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "left-grid"
      }, [_createElementVNode("h5", {
        class: "sub-total mb-0"
      }, "Subtotal")], -1)), _createElementVNode("div", _hoisted_24, [_createElementVNode("h6", _hoisted_25, " $ " + _toDisplayString(_unref(priceConverter)(__props.cartData?.priceWithAddons)) + " USD ", 1)])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", {
        class: "btn-bg bg-del-light",
        onClick: _cache[0] || (_cache[0] = $event => _unref(deleteItem)(__props.cartData.id))
      }, _cache[5] || (_cache[5] = [_createElementVNode("span", {
        class: "material-symbols-outlined material-symbols-filled delete"
      }, "delete", -1)])), __props.cartData.id != 'trip_side_assistance' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "btn-bg bg-edit-light",
        onClick: _cache[1] || (_cache[1] = $event => _unref(toAddons)(__props.cartData.id))
      }, _cache[6] || (_cache[6] = [_createElementVNode("span", {
        class: "material-symbols-outlined material-symbols-filled edit"
      }, "edit", -1)]))) : _createCommentVNode("", true)]), _cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "row d-flex align-items-center mt-3"
      }, [_createElementVNode("div", {
        class: "col-12 bordered"
      }, [_createElementVNode("div", {
        class: "border-bottom"
      })])], -1))], 64);
    };
  }
};