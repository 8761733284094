import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-fluid p-0"
};
const _hoisted_2 = {
  class: "extend-policy-layout overflow-hidden static-space-top"
};
const _hoisted_3 = {
  class: "custom-container"
};
const _hoisted_4 = {
  class: "row d-flex justify-content-center"
};
const _hoisted_5 = {
  class: "col-12"
};
const _hoisted_6 = {
  class: "row"
};
const _hoisted_7 = {
  class: "col-sm-5 col-12"
};
const _hoisted_8 = {
  class: "col-sm-7 col-12"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
    class: "back-button",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
  }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
    alt: "arrow",
    src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
  }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _renderSlot(_ctx.$slots, "nav")]), _createElementVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "body")])])])])])])]);
}