/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "row px-3 price-listing-wrapper mx-0"
};
const _hoisted_2 = {
  class: "col-12"
};
const _hoisted_3 = {
  class: "d-flex total justify-content-between pt-3"
};
const _hoisted_4 = {
  class: "text-uppercase"
};
const _hoisted_5 = {
  class: "cart-btn"
};
import { useRouter } from "vue-router";
import Button from "@/components/primaryButton.vue";
import { toggleCartModal } from "@/helpers/modal";
import { priceConverter } from "@/helpers/priceConverter";
import { track } from "@/analytics/events";
import { toRaw } from "vue";
import { uuid } from "vue-uuid";
export default {
  __name: 'cartListingPrice',
  setup(__props) {
    const {
      useState,
      useActions
    } = createNamespacedHelpers("plans");
    const userStore = createNamespacedHelpers("user");
    const itiraStore = createNamespacedHelpers("itira");
    const router = useRouter();
    const {
      cart
    } = useState(["cart", "itira"]);
    const {
      itira
    } = itiraStore.useState(["itira"]);
    const {
      user
    } = userStore.useState(["user"]);
    const {
      getCustomerInfo
    } = userStore.useGetters(["getCustomerInfo"]);
    const {
      setRedirectingPath
    } = userStore.useActions(["setRedirectingPath"]);
    const {
      fectAllProducts
    } = useActions(["fectAllProducts"]);
    let total = ref(0);
    let getAllPrice = cart.value.map(({
      priceWithAddons
    }) => priceWithAddons);
    total.value = getAllPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let toCheckOut = () => {
      track("Checkout Started", {
        customer_info: toRaw(getCustomerInfo.value),
        value: total.value,
        revenue: 0,
        shipping: 0,
        tax: 0,
        discount: 0,
        currency: 'USD',
        cart_id: itira.value.itira_uuid ?? null,
        order_id: itira.value.itira_uuid ?? null,
        event: uuid.v4(),
        quantity: Object.values(cart.value).length,
        products: Object.values(cart.value).map(value => {
          return {
            product_id: value.id,
            name: value.name,
            price: value.price,
            price_with_addons: value.priceWithAddons,
            quantity: value.quantity
          };
        })
      });
      setRedirectingPath(`/checkout/traveler`);
      router.push({
        path: `/checkout/traveler`
      });
      toggleCartModal();
    };
    let toCountinueShoping = () => {
      track("Continued Shopping", {
        customer_info: toRaw(getCustomerInfo.value),
        cart_id: itira.value.itira_uuid ?? '',
        products: cart.value ? Object.values(cart.value).map(value => {
          return {
            product_id: value.id,
            name: value.name,
            price: value.price,
            price_with_addons: value.priceWithAddons,
            quantity: value.quantity
          };
        }) : "Cart is empty"
      });

      //console.log("toRaw(itira.value)",toRaw(itira.value))
      let {
        itira_uuid,
        quote_id
      } = toRaw(itira.value);
      //console.log("🚀 ~ file: cartListingPrice.vue:58 ~ toCountinueShoping ~ quote_id:", quote_id)
      if (router.currentRoute.value.fullPath != "/plan-listing") {
        // fectAllProducts({ uuid: itira_uuid });
        router.push({
          path: `/plan-listing/${quote_id}`
        });
      }
      toggleCartModal();
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("h6", null, "Total", -1)), _createElementVNode("h6", _hoisted_4, "$" + _toDisplayString(_unref(priceConverter)(_unref(total))) + " USD", 1)]), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "guarantee plan-name w-100"
      }, [_createElementVNode("div", {
        class: "float-end text-end pt-3"
      }, [_createElementVNode("h6", {
        class: "mb-2"
      }, "100% satisfaction guaranteed")])], -1)), _createElementVNode("div", _hoisted_5, [_createVNode(Button, {
        label: 'Checkout',
        class: "mt-4",
        onClick: _unref(toCheckOut)
      }, null, 8, ["onClick"]), _createVNode(Button, {
        label: 'Continue shopping',
        class: "cart-outline-btn mt-5",
        onClick: _unref(toCountinueShoping)
      }, null, 8, ["onClick"])])])]);
    };
  }
};