/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'premium-coverage-plans-comprehensive-coverage',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Premium Coverage Plans (Comprehensive Coverage)",
        meta: [{
          name: 'description',
          content: "Premium Coverage Plans offer more expansive and flexible coverage than Economy Benefit Plans (schedule or fixed benefit). With a slightly higher costs up front than Economy Benefit Plans, Premium Plans offer a broader spectrum of coverage and include trip protection benefits such as baggage loss or trip interruption coverage. The deductible (usually $100) is applied only once during the life of the policy during your first claim. For any subsequent claim, however, your deductible will not need to be paid again. While Premium Coverage Plans allow you to visit any doctor, it is recommended that you stay within your provider network as it will save you up to 10% in copay cost. The cost of receiving treatment is split between you and your provider, with you covering the cost of the deductible and copay (usually 10%) out of pocket and your provider covering the remaining cost of treatment. Before choosing between Premium Benefit Plans or Economy Coverage Plans, it is important to assess your budget and your comfort with assuming risk. Those two factors will dictate which plan is best for you."
        }, {
          property: 'og:description',
          content: "Premium Coverage Plans offer more expansive and flexible coverage than Economy Benefit Plans (schedule or fixed benefit). With a slightly higher costs up front than Economy Benefit Plans, Premium Plans offer a broader spectrum of coverage and include trip protection benefits such as baggage loss or trip interruption coverage. The deductible (usually $100) is applied only once during the life of the policy during your first claim. For any subsequent claim, however, your deductible will not need to be paid again. While Premium Coverage Plans allow you to visit any doctor, it is recommended that you stay within your provider network as it will save you up to 10% in copay cost. The cost of receiving treatment is split between you and your provider, with you covering the cost of the deductible and copay (usually 10%) out of pocket and your provider covering the remaining cost of treatment. Before choosing between Premium Benefit Plans or Economy Coverage Plans, it is important to assess your budget and your comfort with assuming risk. Those two factors will dictate which plan is best for you."
        }, {
          property: 'og:title',
          content: "Premium Coverage Plans (Comprehensive Coverage)"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/premium-coverage-plans-comprehensive-coverage`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/premium-coverage-plans-comprehensive-coverage`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Premium Coverage Plans (Comprehensive Coverage) | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Premium Coverage Plans offer more expansive and flexible coverage than Economy Benefit Plans (schedule or fixed benefit). With a slightly higher costs up front than Economy Benefit Plans, Premium Plans offer a broader spectrum of coverage and include trip protection benefits such as baggage loss or trip interruption coverage. The deductible (usually $100) is applied only once during the life of the policy during your first claim. For any subsequent claim, however, your deductible will not need to be paid again. While Premium Coverage Plans allow you to visit any doctor, it is recommended that you stay within your provider network as it will save you up to 10% in copay cost. The cost of receiving treatment is split between you and your provider, with you covering the cost of the deductible and copay (usually 10%) out of pocket and your provider covering the remaining cost of treatment. Before choosing between Premium Benefit Plans or Economy Coverage Plans, it is important to assess your budget and your comfort with assuming risk. Those two factors will dictate which plan is best for you."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/premium-coverage-plans-comprehensive-coverage`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Premium Coverage Plans (Comprehensive Coverage)\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Premium Coverage Plans offer more expansive and flexible coverage than Economy Benefit Plans (schedule or fixed benefit). With a slightly higher costs up front than Economy Benefit Plans, Premium Plans offer a broader spectrum of coverage and include trip protection benefits such as baggage loss or trip interruption coverage. The deductible (usually $100) is applied only once during the life of the policy during your first claim. For any subsequent claim, however, your deductible will not need to be paid again. While Premium Coverage Plans allow you to visit any doctor, it is recommended that you stay within your provider network as it will save you up to 10% in copay cost. The cost of receiving treatment is split between you and your provider, with you covering the cost of the deductible and copay (usually 10%) out of pocket and your provider covering the remaining cost of treatment. Before choosing between Premium Benefit Plans or Economy Coverage Plans, it is important to assess your budget and your comfort with assuming risk. Those two factors will dictate which plan is best for you.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Premium Coverage Plans (Comprehensive Coverage)</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Premium Coverage Plans offer more expansive and flexible coverage than Economy Benefit Plans (schedule or fixed benefit). With a slightly higher costs up front than Economy Benefit Plans, Premium Plans offer a broader spectrum of coverage and include trip protection benefits such as baggage loss or trip interruption coverage. The deductible (usually $100) is applied only once during the life of the policy during your first claim. For any subsequent claim, however, your deductible will not need to be paid again. While Premium Coverage Plans allow you to visit any doctor, it is recommended that you stay within your provider network as it will save you up to 10% in copay cost. The cost of receiving treatment is split between you and your provider, with you covering the cost of the deductible and copay (usually 10%) out of pocket and your provider covering the remaining cost of treatment. Before choosing between Premium Benefit Plans or Economy Coverage Plans, it is important to assess your budget and your comfort with assuming risk. Those two factors will dictate which plan is best for you.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};