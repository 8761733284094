import { axios } from "@/config/axios";
import {
  userDashboard,
  userDashboardDetailPolicy,
  editPolicy,
  updateBeneficiary,
  extentPolicy,
  cancelPolicy,
  productDocument,
  planBrochure,
} from "@/endpoints";

import { notify } from "@kyvg/vue3-notification";

import { STATE_STATUSES } from "@/helpers/constants";

export const actions = {
  //get Userdashboard data on profile
  async getUserDashboardData({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(userDashboard);

      if (response.data._metadata.message && response.data.records != null) {
        commit("setUserDashboard", response.data.records);
      }

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async resetsetUserDashboard({ commit }, payload) {
    commit("resetsetUserDashboard");
  },

  //  detail dashboard action

  async extendPolicyPlanDetail({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(userDashboardDetailPolicy(payload));

      if (response.data._metadata.message && response.data.records != null) {
        commit("extendPolicyPlanDetail", response.data.records);
      }
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async editExtentPolicy({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      await axios.post(editPolicy, payload);
      // field: 'age',
      if (
        payload?.field == "age" ||
        payload?.field == "destination" ||
        payload?.field == "notUpdateDirectly"
      ) {
        notify({
          type: "Success",
          title: "We will contact you",
          text: "Our CSx Team will contact you soon.",
        });
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        return;
      }

      notify({
        type: "Success",
        title: "Updated Successfully",
        text: "Your data has been updated",
      });
      commit("editExtentPolicy", payload);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      notify({
        type: "error",
        title: "Data no updated",
        text: "Some thing bad happen data did not update",
      });
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async extendPolicyOption({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(extentPolicy, payload);

      if (
        payload?.field == "age" ||
        payload?.field == "destination" ||
        payload?.field == "notUpdateDirectly"
      ) {
        notify({
          type: "Success",
          title: "We will contact you",
          text: "Our CSx Team will contact you soon.",
        });
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        return;
      }
    } catch (e) {
      notify({
        type: "error",
        title: "Data no updated",
        text: "Some thing bad happen data did not update",
      });
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async cancelPolicyOption({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(cancelPolicy, payload);
      notify({
        type: "Success",
        title: "Updated Successfully",
        text: "Your data has been updated",
      });
      // commit('editExtentPolicy', payload);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      notify({
        type: "error",
        title: "Data no updated",
        text: "Some thing bad happen data did not update",
      });
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async updateBeneficiaryOption({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(updateBeneficiary, payload);
      notify({
        type: "Success",
        title: "Updated Successfully",
        text: "Your data has been updated",
      });
      let { name, phone, email, address, relation } = payload;

      commit("updatebeneficieryInfo", {
        beneficiary_name: name,
        beneficiary_phone: phone,
        beneficiary_email: email,
        beneficiary_address: address,
        beneficiary_relation: relation,
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      notify({
        type: "error",
        title: "Data no updated",
        text: "Some thing bad happen data did not update",
      });
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async filesDownload({ commit }, payload) {

    commit("setFileLoader", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(payload);

      commit("setFileLoader", STATE_STATUSES.READY);

      return response.data?.data;
    } catch (e) {
      console.log("error", e);

      commit("setFileLoader", STATE_STATUSES.ERROR);
    }
  },
  async resetDashboardStore({ commit }) {
    commit("resetDashboard");
  },
};
