/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'stolen-passportsvisas',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Stolen Passports/Visas",
        meta: [{
          name: 'description',
          content: "Coverage, typically in the form of reimbursement of travel costs, if your passport or visa is stolen. This benefit is often found within trip cancellation/trip interruption coverage. Apart from monetary compensation, G1G will assist you in acquiring a new passport or visa. This coverage is becoming more and more relevant with identity theft rising around the world. Additionally, we offer coverage for identity theft. Check your policy for details."
        }, {
          property: 'og:description',
          content: "Coverage, typically in the form of reimbursement of travel costs, if your passport or visa is stolen. This benefit is often found within trip cancellation/trip interruption coverage. Apart from monetary compensation, G1G will assist you in acquiring a new passport or visa. This coverage is becoming more and more relevant with identity theft rising around the world. Additionally, we offer coverage for identity theft. Check your policy for details."
        }, {
          property: 'og:title',
          content: "Stolen Passports/Visas"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/stolen-passportsvisas`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/stolen-passportsvisas`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Stolen Passports/Visas | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Coverage, typically in the form of reimbursement of travel costs, if your passport or visa is stolen. This benefit is often found within trip cancellation/trip interruption coverage. Apart from monetary compensation, G1G will assist you in acquiring a new passport or visa. This coverage is becoming more and more relevant with identity theft rising around the world. Additionally, we offer coverage for identity theft. Check your policy for details."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/stolen-passportsvisas`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Stolen Passports/Visas\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Coverage, typically in the form of reimbursement of travel costs, if your passport or visa is stolen. This benefit is often found within trip cancellation/trip interruption coverage. Apart from monetary compensation, G1G will assist you in acquiring a new passport or visa. This coverage is becoming more and more relevant with identity theft rising around the world. Additionally, we offer coverage for identity theft. Check your policy for details.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Stolen Passports/Visas</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Coverage, typically in the form of reimbursement of travel costs, if your passport or visa is stolen. This benefit is often found within trip cancellation/trip interruption coverage. Apart from monetary compensation, G1G will assist you in acquiring a new passport or visa. This coverage is becoming more and more relevant with identity theft rising around the world. Additionally, we offer coverage for identity theft. Check your policy for details.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};