import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "select-plan-btn buy-now"
};
const _hoisted_2 = ["disabled"];
const _hoisted_3 = {
  key: 0,
  class: "spinner-border",
  role: "status"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("button", {
    id: "buy-now2",
    class: "btn btn-primary blue-btn",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('onClick', $props.id, $props.data)),
    disabled: $props.disabled
  }, [$props.loading ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.label), 1)], 8, _hoisted_2)]);
}