import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { AdvancedImage } from '@cloudinary/vue';
import { Cloudinary } from '@cloudinary/url-gen';
export default {
  __name: 'CloudImage',
  props: {
    imageName: String
  },
  setup(__props) {
    const props = __props;

    // Create a Cloudinary instance and set your cloud name.
    const cld = new Cloudinary({
      cloud: {
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        apiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
        apiSecret: process.env.VUE_APP_CLOUDINARY_SECRET_KEY
      }
    });
    const myImg = cld.image(props.imageName);
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(AdvancedImage), {
        cldImg: _unref(myImg)
      }, null, 8, ["cldImg"]);
    };
  }
};