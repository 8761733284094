/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'enchanced-add',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Enchanced AD&D",
        meta: [{
          name: 'description',
          content: "Accidental Death and Dismemberment (AD&D) is a benefit which provides monetary compensation to an insured's beneficiary, should the insured perish or suffer dismemberment in an accident during the course of their coverage. Dismemberment is the event that the insured person loses a body part or its function.Enhanced AD&D is an upgradable feature that qualifies your beneficiary for larger payouts in the event that something terrible should happen to you while traveling abroad. Check your policies details for exact amounts of upgradable coverage.Enhanced AD&D coverage is available for Add-on with all Safe Travel, Patriot and Diplomat plans. Select this option and receive extended coverage for AD&D."
        }, {
          property: 'og:description',
          content: "Accidental Death and Dismemberment (AD&D) is a benefit which provides monetary compensation to an insured's beneficiary, should the insured perish or suffer dismemberment in an accident during the course of their coverage. Dismemberment is the event that the insured person loses a body part or its function.Enhanced AD&D is an upgradable feature that qualifies your beneficiary for larger payouts in the event that something terrible should happen to you while traveling abroad. Check your policies details for exact amounts of upgradable coverage.Enhanced AD&D coverage is available for Add-on with all Safe Travel, Patriot and Diplomat plans. Select this option and receive extended coverage for AD&D."
        }, {
          property: 'og:title',
          content: "Enchanced AD&D"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/enchanced-add`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/enchanced-add`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Enchanced AD&D | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Accidental Death and Dismemberment (AD&D) is a benefit which provides monetary compensation to an insured's beneficiary, should the insured perish or suffer dismemberment in an accident during the course of their coverage. Dismemberment is the event that the insured person loses a body part or its function.Enhanced AD&D is an upgradable feature that qualifies your beneficiary for larger payouts in the event that something terrible should happen to you while traveling abroad. Check your policies details for exact amounts of upgradable coverage.Enhanced AD&D coverage is available for Add-on with all Safe Travel, Patriot and Diplomat plans. Select this option and receive extended coverage for AD&D."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/enchanced-add`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Enchanced AD&D\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Accidental Death and Dismemberment (AD&D) is a benefit which provides monetary compensation to an insured's beneficiary, should the insured perish or suffer dismemberment in an accident during the course of their coverage. Dismemberment is the event that the insured person loses a body part or its function.Enhanced AD&D is an upgradable feature that qualifies your beneficiary for larger payouts in the event that something terrible should happen to you while traveling abroad. Check your policies details for exact amounts of upgradable coverage.Enhanced AD&D coverage is available for Add-on with all Safe Travel, Patriot and Diplomat plans. Select this option and receive extended coverage for AD&D.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Enchanced AD&amp;D</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Accidental Death and Dismemberment (AD&amp;D) is a benefit which provides monetary compensation to an insured&#39;s beneficiary, should the insured perish or suffer dismemberment in an accident during the course of their coverage. Dismemberment is the event that the insured person loses a body part or its function.Enhanced AD&amp;D is an upgradable feature that qualifies your beneficiary for larger payouts in the event that something terrible should happen to you while traveling abroad. Check your policies details for exact amounts of upgradable coverage.Enhanced AD&amp;D coverage is available for Add-on with all Safe Travel, Patriot and Diplomat plans. Select this option and receive extended coverage for AD&amp;D.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};