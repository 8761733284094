/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'pre-existing-period',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Pre-Existing Period ",
        meta: [{
          name: 'description',
          content: "A pre-existing condition is simply any medical condition or health issue that exists prior to the beginning of your policy coverage period. All plans have varied periods of time in which they deem an illness to not be considered pre-existing, this is known as a pre-existing period. You must be medication and symptom free for that time period in order for the illness to not be considered pre-existing. All travel policies are for primarily emergency medical emergencies only and not be used to seek treatment for pre-existing or continuing illness. If you are concerned for the coverage of traumatic emergencies resulting from a pre-existing illness, such as heart attack or stroke, than select the acute onset of a pre-existing conditionâ€ filter/benefit in the search results page. An acute onset condition is disability or medical condition acquired suddenly or over a short period of time, including psychological/psychiatric conditions following a traumatic event, OR a situation in which a pre-exisintg disability or condition comes sharply to a crisis. "
        }, {
          property: 'og:description',
          content: "A pre-existing condition is simply any medical condition or health issue that exists prior to the beginning of your policy coverage period. All plans have varied periods of time in which they deem an illness to not be considered pre-existing, this is known as a pre-existing period. You must be medication and symptom free for that time period in order for the illness to not be considered pre-existing. All travel policies are for primarily emergency medical emergencies only and not be used to seek treatment for pre-existing or continuing illness. If you are concerned for the coverage of traumatic emergencies resulting from a pre-existing illness, such as heart attack or stroke, than select the acute onset of a pre-existing conditionâ€ filter/benefit in the search results page. An acute onset condition is disability or medical condition acquired suddenly or over a short period of time, including psychological/psychiatric conditions following a traumatic event, OR a situation in which a pre-exisintg disability or condition comes sharply to a crisis. "
        }, {
          property: 'og:title',
          content: "Pre-Existing Period "
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/pre-existing-period`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/pre-existing-period`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Pre-Existing Period  | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "A pre-existing condition is simply any medical condition or health issue that exists prior to the beginning of your policy coverage period. All plans have varied periods of time in which they deem an illness to not be considered pre-existing, this is known as a pre-existing period. You must be medication and symptom free for that time period in order for the illness to not be considered pre-existing. All travel policies are for primarily emergency medical emergencies only and not be used to seek treatment for pre-existing or continuing illness. If you are concerned for the coverage of traumatic emergencies resulting from a pre-existing illness, such as heart attack or stroke, than select the acute onset of a pre-existing conditionâ€ filter/benefit in the search results page. An acute onset condition is disability or medical condition acquired suddenly or over a short period of time, including psychological/psychiatric conditions following a traumatic event, OR a situation in which a pre-exisintg disability or condition comes sharply to a crisis. "
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/pre-existing-period`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Pre-Existing Period \", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"A pre-existing condition is simply any medical condition or health issue that exists prior to the beginning of your policy coverage period. All plans have varied periods of time in which they deem an illness to not be considered pre-existing, this is known as a pre-existing period. You must be medication and symptom free for that time period in order for the illness to not be considered pre-existing. All travel policies are for primarily emergency medical emergencies only and not be used to seek treatment for pre-existing or continuing illness. If you are concerned for the coverage of traumatic emergencies resulting from a pre-existing illness, such as heart attack or stroke, than select the acute onset of a pre-existing conditionâ€ filter/benefit in the search results page. An acute onset condition is disability or medical condition acquired suddenly or over a short period of time, including psychological/psychiatric conditions following a traumatic event, OR a situation in which a pre-exisintg disability or condition comes sharply to a crisis. \" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Pre-Existing Period </h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">A pre-existing condition is simply any medical condition or health issue that exists prior to the beginning of your policy coverage period. All plans have varied periods of time in which they deem an illness to not be considered pre-existing, this is known as a pre-existing period. You must be medication and symptom free for that time period in order for the illness to not be considered pre-existing. All travel policies are for primarily emergency medical emergencies only and not be used to seek treatment for pre-existing or continuing illness. If you are concerned for the coverage of traumatic emergencies resulting from a pre-existing illness, such as heart attack or stroke, than select the acute onset of a pre-existing conditionâ€ filter/benefit in the search results page. An acute onset condition is disability or medical condition acquired suddenly or over a short period of time, including psychological/psychiatric conditions following a traumatic event, OR a situation in which a pre-exisintg disability or condition comes sharply to a crisis. </div></div></div></div>", 1)])))])])])], 64);
    };
  }
};