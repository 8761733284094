import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "new-claims-wrapper all-claims-wrapper static-space-top"
};
const _hoisted_2 = {
  class: "custom-container"
};
const _hoisted_3 = {
  class: "row d-flex justify-content-center"
};
const _hoisted_4 = {
  class: "col-lg-11"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: "back-button",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
  }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
    class: "",
    alt: "arrow",
    src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
  }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _renderSlot(_ctx.$slots, "default")])])])]);
}