/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'schedule-of-benefit-plans',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Schedule of Benefit Plans",
        meta: [{
          name: 'description',
          content: "Schedule of Benefit Plans (also known as Economy Plans) have a more inexpensive premium when compared to Premium Benefit Plans. With an Economy Benefit Plan you can see any doctor or seek care in any hospital. The amount the policy will pay for treatment is pre-determined according to a benefit summary. They offer very little to no trip protection benefits such as baggage loss or trip interruption coverage. They contribute a predetermined amount to a given medical cost; however, any remaining costs fall on you. The benefit summary is scaled to cover the costs of small medical emergencies, but often falls short of covering the entire cost of major surgeries or lengthy hospital stays. The deductible (usually $100) is applied per claim. For example, if you visit the doctor for cold or flu-like symptoms a deductible is required. However, if you visit the doctor again for another unrelated claim then your deductible is due again. While Economy Benefit Plans might not provide you with coverage in all the areas you want, it will save you some up front cost if you are willing to assume additional risk."
        }, {
          property: 'og:description',
          content: "Schedule of Benefit Plans (also known as Economy Plans) have a more inexpensive premium when compared to Premium Benefit Plans. With an Economy Benefit Plan you can see any doctor or seek care in any hospital. The amount the policy will pay for treatment is pre-determined according to a benefit summary. They offer very little to no trip protection benefits such as baggage loss or trip interruption coverage. They contribute a predetermined amount to a given medical cost; however, any remaining costs fall on you. The benefit summary is scaled to cover the costs of small medical emergencies, but often falls short of covering the entire cost of major surgeries or lengthy hospital stays. The deductible (usually $100) is applied per claim. For example, if you visit the doctor for cold or flu-like symptoms a deductible is required. However, if you visit the doctor again for another unrelated claim then your deductible is due again. While Economy Benefit Plans might not provide you with coverage in all the areas you want, it will save you some up front cost if you are willing to assume additional risk."
        }, {
          property: 'og:title',
          content: "Schedule of Benefit Plans"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/schedule-of-benefit-plans`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/schedule-of-benefit-plans`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Schedule of Benefit Plans | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Schedule of Benefit Plans (also known as Economy Plans) have a more inexpensive premium when compared to Premium Benefit Plans. With an Economy Benefit Plan you can see any doctor or seek care in any hospital. The amount the policy will pay for treatment is pre-determined according to a benefit summary. They offer very little to no trip protection benefits such as baggage loss or trip interruption coverage. They contribute a predetermined amount to a given medical cost; however, any remaining costs fall on you. The benefit summary is scaled to cover the costs of small medical emergencies, but often falls short of covering the entire cost of major surgeries or lengthy hospital stays. The deductible (usually $100) is applied per claim. For example, if you visit the doctor for cold or flu-like symptoms a deductible is required. However, if you visit the doctor again for another unrelated claim then your deductible is due again. While Economy Benefit Plans might not provide you with coverage in all the areas you want, it will save you some up front cost if you are willing to assume additional risk."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/schedule-of-benefit-plans`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Schedule of Benefit Plans\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Schedule of Benefit Plans (also known as Economy Plans) have a more inexpensive premium when compared to Premium Benefit Plans. With an Economy Benefit Plan you can see any doctor or seek care in any hospital. The amount the policy will pay for treatment is pre-determined according to a benefit summary. They offer very little to no trip protection benefits such as baggage loss or trip interruption coverage. They contribute a predetermined amount to a given medical cost; however, any remaining costs fall on you. The benefit summary is scaled to cover the costs of small medical emergencies, but often falls short of covering the entire cost of major surgeries or lengthy hospital stays. The deductible (usually $100) is applied per claim. For example, if you visit the doctor for cold or flu-like symptoms a deductible is required. However, if you visit the doctor again for another unrelated claim then your deductible is due again. While Economy Benefit Plans might not provide you with coverage in all the areas you want, it will save you some up front cost if you are willing to assume additional risk.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Schedule of Benefit Plans</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Schedule of Benefit Plans (also known as Economy Plans) have a more inexpensive premium when compared to Premium Benefit Plans. With an Economy Benefit Plan you can see any doctor or seek care in any hospital. The amount the policy will pay for treatment is pre-determined according to a benefit summary. They offer very little to no trip protection benefits such as baggage loss or trip interruption coverage. They contribute a predetermined amount to a given medical cost; however, any remaining costs fall on you. The benefit summary is scaled to cover the costs of small medical emergencies, but often falls short of covering the entire cost of major surgeries or lengthy hospital stays. The deductible (usually $100) is applied per claim. For example, if you visit the doctor for cold or flu-like symptoms a deductible is required. However, if you visit the doctor again for another unrelated claim then your deductible is due again. While Economy Benefit Plans might not provide you with coverage in all the areas you want, it will save you some up front cost if you are willing to assume additional risk.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};