import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "input-wrapper"
};
const _hoisted_2 = ["type", "name", "id", "value"];
const _hoisted_3 = {
  class: "label"
};
const _hoisted_4 = {
  class: "material-symbols-outlined"
};
const _hoisted_5 = {
  key: 0,
  class: "error-label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", _mergeProps({
    type: $props.type,
    name: $props.name,
    id: $props.name,
    value: $props.value,
    ref: $props.ref,
    required: ""
  }, _ctx.$attrs, {
    onInput: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', $event.target.value))
  }), null, 16, _hoisted_2), _createElementVNode("label", _hoisted_3, _toDisplayString($props.label), 1), _createElementVNode("span", _hoisted_4, _toDisplayString($props.icon), 1), $props.error[0]?.$message ? (_openBlock(), _createElementBlock("label", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "material-symbols-outlined"
  }, " error ", -1)), _createTextVNode(" " + _toDisplayString($props.error[0]?.$message), 1)])) : _createCommentVNode("", true)]);
}