/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'age',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Age",
        meta: [{
          name: 'description',
          content: "Age is the period of time a policyholder has been alive. Since underwriters usually don't require a medical examination prior to purchasing Travel Medical Coverage, age is one of the most important factors in determining a person's insurance premium.Most policies use age to help determine the likelihood of a policyholder filing a claim, in turn, increasing your policy premium. For policyholders over the age of 69, underwriters will occasionally limit the scope of coverage to exclude particular features. For example, if a policyholder is 70 years old, coverage such as a the Acute Onset of a Preexisting Condition may not be covered. We recommend that you explore your policy details before purchasing a plan to ensure that it offers the necessary coverage for your trip.Age is an especially important factor for people acquiring multiple policies. If you are purchasing plans for family members, one of which is over the age of 69, it's best to purchase their policy separately since their age will increase the overall premium.    "
        }, {
          property: 'og:description',
          content: "Age is the period of time a policyholder has been alive. Since underwriters usually don't require a medical examination prior to purchasing Travel Medical Coverage, age is one of the most important factors in determining a person's insurance premium.Most policies use age to help determine the likelihood of a policyholder filing a claim, in turn, increasing your policy premium. For policyholders over the age of 69, underwriters will occasionally limit the scope of coverage to exclude particular features. For example, if a policyholder is 70 years old, coverage such as a the Acute Onset of a Preexisting Condition may not be covered. We recommend that you explore your policy details before purchasing a plan to ensure that it offers the necessary coverage for your trip.Age is an especially important factor for people acquiring multiple policies. If you are purchasing plans for family members, one of which is over the age of 69, it's best to purchase their policy separately since their age will increase the overall premium.    "
        }, {
          property: 'og:title',
          content: "Age"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/age`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/age`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Age | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "Age is the period of time a policyholder has been alive. Since underwriters usually don't require a medical examination prior to purchasing Travel Medical Coverage, age is one of the most important factors in determining a person's insurance premium.Most policies use age to help determine the likelihood of a policyholder filing a claim, in turn, increasing your policy premium. For policyholders over the age of 69, underwriters will occasionally limit the scope of coverage to exclude particular features. For example, if a policyholder is 70 years old, coverage such as a the Acute Onset of a Preexisting Condition may not be covered. We recommend that you explore your policy details before purchasing a plan to ensure that it offers the necessary coverage for your trip.Age is an especially important factor for people acquiring multiple policies. If you are purchasing plans for family members, one of which is over the age of 69, it's best to purchase their policy separately since their age will increase the overall premium.    "
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/age`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Age\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"Age is the period of time a policyholder has been alive. Since underwriters usually don't require a medical examination prior to purchasing Travel Medical Coverage, age is one of the most important factors in determining a person's insurance premium.Most policies use age to help determine the likelihood of a policyholder filing a claim, in turn, increasing your policy premium. For policyholders over the age of 69, underwriters will occasionally limit the scope of coverage to exclude particular features. For example, if a policyholder is 70 years old, coverage such as a the Acute Onset of a Preexisting Condition may not be covered. We recommend that you explore your policy details before purchasing a plan to ensure that it offers the necessary coverage for your trip.Age is an especially important factor for people acquiring multiple policies. If you are purchasing plans for family members, one of which is over the age of 69, it's best to purchase their policy separately since their age will increase the overall premium. \" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Age</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">Age is the period of time a policyholder has been alive. Since underwriters usually don&#39;t require a medical examination prior to purchasing Travel Medical Coverage, age is one of the most important factors in determining a person&#39;s insurance premium.Most policies use age to help determine the likelihood of a policyholder filing a claim, in turn, increasing your policy premium. For policyholders over the age of 69, underwriters will occasionally limit the scope of coverage to exclude particular features. For example, if a policyholder is 70 years old, coverage such as a the Acute Onset of a Preexisting Condition may not be covered. We recommend that you explore your policy details before purchasing a plan to ensure that it offers the necessary coverage for your trip.Age is an especially important factor for people acquiring multiple policies. If you are purchasing plans for family members, one of which is over the age of 69, it&#39;s best to purchase their policy separately since their age will increase the overall premium. </div></div></div></div>", 1)])))])])])], 64);
    };
  }
};