import { toRaw, computed } from "vue";
import { notify } from "@kyvg/vue3-notification";
import {STATE_STATUSES} from "@/helpers/constants";
export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },

  getAllPlans(state, payload) {
    state.allPlans = payload;
  },

  updateUserOrder(state, payload) {
    state.userOrder = payload;
  },
  setAllCardProduct(state, payload) {
    state.cart = payload;
  },
  getSinglePlan(state, payload) {
    state.fullDetailPlan = payload;
  },
  STATE_STATUSES_CART(state, payload) {
    state.statuscartListingloaing = payload;
  },
  STATE_STATUSES_ADD_CART(state, payload) {
    state.statuscartAddLoading = payload;
  },
  //all set createOrder to send in final
  addToCreateOrder(state, payload) {
    // state.createOrder = { ...payload };

    state.createOrder != null ? [...state.createOrder, payload] : [payload];
  },
  empty_cart(state, payload) {
    state.cart = [];
  },

  setGuestUserID(state, payload) {
    state.guest_user = "";
    state.guest_user = payload;
  },
  cartItemNumber(state, payload) {
    state.cartItemNumber = payload;
  },
  emergencyMedEvac(state, payload) {
    state.emergencyMedEvac = payload;
  },
  order_summary(state, payload) {
    state.orderSummary = payload;
  },
  loginUserData(state, payload) {
    state.loginUserData = payload;
  },
  updatePlan(state, payload) {
    let { allPlans,comparePlans } = state;
    let { plans } = toRaw(allPlans);
    let { price, travelers, upgradesCalculated , all_benefits , plan_benefits} = payload.records;
    let { coverage, deductible, plan_id } = payload;

    let updatePlans = plans.map((plan) => {
      return plan.PlanId === plan_id
        ? {
            ...plan,
            price,
            all_benefits,
            plan_benefits,
            coverage,
            selectedCoverage: coverage,
            deductible,
            selectedDeductible: deductible,
            eligibleTravelers: travelers,
            extra_add_on: upgradesCalculated,
          }
        : plan;
    });
    // price update for compare page
if (toRaw(comparePlans).length>0){
  let updatePlansForCompare = comparePlans.map((plan) => {
    return plan.PlanId === plan_id
        ? {
          ...plan,
          price,
          all_benefits,
          plan_benefits,
          coverage,
          selectedCoverage: coverage,
          deductible,
          selectedDeductible: deductible,
          eligibleTravelers: travelers,
          extra_add_on: upgradesCalculated,
        }
        : plan;
  });
  state.comparePlans = updatePlansForCompare;
}

   // console.log(updatePlans,'updatePlans');

    state.allPlans.plans = updatePlans;
    state.statusPlanPrice = "ready";
  },

  fullDetailPlan(state, payload) {
    state.fullDetailPlan = { ...state.fullDetailPlan, ...payload };
  },

  updatePlanForSpecificUpgradesCost(state, payload) {
   // console.log("updatePlanForSpecificUpgradesCost",payload);
let { plan_id, total_price } = payload;
    let { allPlans } = state;
    let { plans } = toRaw(allPlans);
    let updatePlan = plans.map((plan) => {

      return plan.PlanId === plan_id
          ? {
            ...plan,
            price:total_price,
          }
          : plan;
    });

    state.allPlans.plans = updatePlan;
    state.statusPlanPrice = "ready";
  },







  statusPlanPrice(state, payload) {
    state.statusPlanPrice = payload;
  },

  setAddOns(state, payload) {
    state.selectedAddOns = payload;
  },
  addOnExtraAdd(state, payload) {
      let { ID } = payload;
    state.selectedAddOns = [...state.selectedAddOns, payload];
    state.selectedAddOnsIDs = [...state.selectedAddOnsIDs, ID];
  },
  specificUpgradesCost(state, payload) {
   // console.log("specificUpgradesCost mutation", payload);
    state.specificUpgradesCostOnAPICallType = payload;
  },

  removeOnExtraAdd(state, payload) {
    state.selectedAddOns = toRaw(state.selectedAddOns).filter(
      ({ ID }) => ID != payload.ID
    );
    state.selectedAddOnsIDs = toRaw(state.selectedAddOnsIDs).filter(
      (ID) => ID != payload.ID
    );


  },

  updatePolicyOption(state, payload) {
    state.selected_add_on_cancel_for_any_reason = payload;
  },

  //reset resetTotalAmount
  resetTotalAmount(state, { plan_id }) {
    let { allPlans } = state;
    let { plans } = toRaw(allPlans);

    let plan = computed(() => {
      return plans.filter((plan) => plan.PlanId == plan_id);
    });

    state.total_amount = parseFloat(toRaw(plan.value[0])?.price?.total_cost);
  },

  //reset selectedAddOns
  resetAddOns(state, payload) {
    state.selectedAddOns = [];
    state.selectedAddOnsIDs = [];
    state.selected_add_on_cancel_for_any_reason = null;
  },
  //UpdateOnExtraAdd
  UpdateOnExtraAdd(state, payload) {
    state.selectedAddOns = state.selectedAddOns.map((addOn) => {
      return payload.ID == addOn.ID ? payload : addOn;
    });

    state.selectedAddOnsIDs = state.selectedAddOnsIDs.map((addOnID) => {
      return payload.ID == addOnID.ID ? payload.ID : addOnID;
    });
  },

  calculateTotalAmountUpdate(state, { plan_id }) {
    // let {
    //   selectedAddOns,
    //   selected_add_on_cancel_for_any_reason,
    //   allPlans,
    //   trip_side_assistance,
    // } = state;

    // let allTotal = 0,
    //   addOnsTotal = 0,
    //   policyOptionPrice = 0,
    //   tripSideAssistancePrice = 0;

    // if (toRaw(selectedAddOns).length > 0) {
    //   addOnsTotal = toRaw(selectedAddOns)?.reduce((totalSum, { Price }) => {
    //     return totalSum + parseFloat(Price);
    //   }, 0);
    // }

    // if (
    //   toRaw(selected_add_on_cancel_for_any_reason) != null &&
    //   toRaw(selected_add_on_cancel_for_any_reason) != undefined
    // ) {
    //   policyOptionPrice = parseFloat(
    //     toRaw(selected_add_on_cancel_for_any_reason)?.Price
    //   );
    // }

    // if (toRaw(trip_side_assistance) != null) {
    //   tripSideAssistancePrice = toRaw(trip_side_assistance)?.price;
    // }

    // let planPrice = parseFloat(toRaw(plan.value[0].price.total_cost));
    // allTotal =
    //   addOnsTotal + policyOptionPrice + planPrice + tripSideAssistancePrice;
    // state.total_amount = parseFloat(allTotal).toFixed(2);
  },
  resetCreateOrderAndHolderUpdatePlanPrice(state) {
    state.createOrder = [];
    state.holdUpdatePlanPrice = null;
    state.total_amount = 0;
  },
  addingComparePlan(state, payload) {
    let { allPlans, comparePlans, fullDetailPlan } = state;
    let { plans } = toRaw(allPlans);


    /*console.log('payload mutation',payload)
    console.log('plans',plans)
    console.log('fullDetailPlan',fullDetailPlan)
    console.log('comparePlans',toRaw(comparePlans))*/

    let searchPlan = toRaw(comparePlans).filter(
      ({ PlanId }) => PlanId == payload
    );



    if (searchPlan.length > 0) {
      state.comparePlans = toRaw(comparePlans).filter(
        ({ PlanId }) => PlanId != payload
      );
      return;
    }

    if (toRaw(comparePlans).length > 2) {
      notify({
        type: "error",
        title: "Adding Compare Plan",
        text: "you can't add plan more than three plans",
      });

      return;
    }

    let fetchPlan = plans.filter(({ PlanId }) => PlanId == payload);
   // console.log('fetchPlan',fetchPlan)
    state.comparePlans = [...toRaw(comparePlans), ...fetchPlan];


  },
  resetComparePlans(state) {
    state.comparePlans = [];
  },
  resetOrderSummary(state) {
    state.orderSummary = null;
  },
  resetSelectedAddon(state) {
    state.selectedAddOns = [];
    state.selectedAddOnsIDs = [];
  },
  resetAllSession(state) {
    state.allPlans = null;
    state.fullDetailPlan = null;
    state.cart = [];
    state.cartItemNumber = null;
    state.emergencyMedEvac = null;
    state.userOrder = null;
    state.createOrder = [];
    state.makeOrderInfo = null;
    state.guest_user = "";
    state.selectedAddOns = [];
    state.comparePlans = [];
    state.selected_add_on_cancel_for_any_reason = null;
    state.total_amount = 0;
    state.trip_side_assistance = null;
  },

  addBeneficiaryAndBiiling(state, payload) {
    state.makeOrderInfo = { ...state.makeOrderInfo, ...payload };
  },
  updateTravelerForAllEmails(state, payload) {
    // console.log("updateTravelerForAllEmails" , payload)
    state.makeOrderInfo = payload;
  },

  setTripSideAssistance(state, payload) {
    state.trip_side_assistance = payload;
  },
  setBlueRibbonBag(state, payload) {
    state.blue_ribbon_bag = payload;
  },
  RESET_BLUE_RIBBON_BAG(state) {
    // let test = toRaw(blue_ribbon_bag.value)
    state.blue_ribbon_bag =  null;
  },

};
