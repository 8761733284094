/* unplugin-vue-components disabled */import __unplugin_components_0 from '/vercel/path0/src/components/dashboard/layout.vue';
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "edit-sub-details-save"
};
const _hoisted_2 = {
  class: "user-info"
};
const _hoisted_3 = {
  class: "my-3"
};
const _hoisted_4 = {
  class: "row"
};
const _hoisted_5 = {
  class: "input-wrapper m-0 countries"
};
const _hoisted_6 = {
  class: "mt-3"
};
const _hoisted_7 = ["disabled"];
const _hoisted_8 = {
  key: 0,
  class: "spinner-border",
  role: "status"
};
import { useRoute, useRouter } from "vue-router";
import vSelect from "vue-select";
import { editTravelerResidenceValidation, editTravelerResidence, editTravelerPhone } from "@/composable/vuelidate";
import { ISO2, ISO2ToFullName } from "@/helpers/converter";
import { notify } from "@kyvg/vue3-notification";
import { track } from "@/analytics/events";
import { toRaw } from "vue";
export default {
  __name: 'travelDetailsResidence',
  setup(__props) {
    const countries = createNamespacedHelpers("countries");
    const {
      useActions,
      useState
    } = createNamespacedHelpers("policies");
    const {
      selectedTraveler,
      updateTravelerLoading
    } = useState(["selectedTraveler", "updateTravelerLoading"]);
    const {
      updateTravelerAgeResidenceCitizenship
    } = useActions(["updateTravelerAgeResidenceCitizenship"]);
    const {
      onlyCountries,
      allCountries
    } = countries.useGetters(["onlyCountries", "allCountries"]);
    let {
      residence_iso2,
      traveler_uuid
    } = toRaw(selectedTraveler.value);
    editTravelerResidence.traveler_residence = ISO2ToFullName(toRaw(allCountries.value), residence_iso2);
    const route = useRoute();
    const router = useRouter();
    const {
      order_no
    } = route.params;
    const updateTravelsDetails = async () => {
      if (await editTravelerResidenceValidation.value.$validate()) {
        await updateTravelerAgeResidenceCitizenship({
          old_residence: residence_iso2,
          residence: ISO2(toRaw(allCountries.value), editTravelerResidence.traveler_residence),
          order_id: order_no,
          traveler_uuid
        });
        track("Customer portal - Update Travel Residence Details", {
          old_residence: residence_iso2,
          residence: ISO2(toRaw(allCountries.value), editTravelerResidence.traveler_residence),
          order_id: order_no,
          traveler_uuid
        });
        notify({
          type: "success",
          title: "Ticket Generated",
          text: "Your citizenship updating request has been submit our cx team will be ping back asp "
        });
        router.go(-1);
      }
    };
    const loading = computed(() => {
      return updateTravelerLoading.value == "pending" ? true : false;
    });
    return (_ctx, _cache) => {
      const _component_Layout = __unplugin_components_0;
      return _openBlock(), _createBlock(_component_Layout, null, {
        body: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[7] || (_cache[7] = _createElementVNode("div", {
          class: "heading-edits"
        }, [_createElementVNode("img", {
          alt: "",
          src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/userEdit.svg"
        }), _createElementVNode("h3", null, "Edit traveler details • Residence")], -1)), _createElementVNode("div", _hoisted_2, [_cache[5] || (_cache[5] = _createElementVNode("h4", {
          class: "h4-details"
        }, " Please use your legal Residence as it appears on your passport OR banking information. ", -1)), _cache[6] || (_cache[6] = _createElementVNode("h3", {
          class: "title"
        }, "Primary Traveler", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
          class: _normalizeClass(["col-md-6", {
            effectStyles: _unref(editTravelerResidence).traveler_residence,
            error: _unref(editTravelerResidenceValidation).traveler_residence.$errors.length
          }])
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(_unref(vSelect), {
          modelValue: _unref(editTravelerResidence).traveler_residence,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(editTravelerResidence).traveler_residence = $event),
          class: _normalizeClass({
            error: _unref(editTravelerResidenceValidation).traveler_residence.$errors.length
          }),
          options: _unref(onlyCountries)
        }, null, 8, ["modelValue", "class", "options"]), _cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "material-symbols-outlined"
        }, " home ", -1)), _cache[2] || (_cache[2] = _createElementVNode("label", {
          class: "floating-label"
        }, "Country Of Residence", -1))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(editTravelerResidenceValidation).traveler_residence.$errors, err => {
          return _openBlock(), _createElementBlock("div", {
            key: err.$uuid,
            class: "error-label filters-error pt-0"
          }, [_cache[3] || (_cache[3] = _createElementVNode("span", {
            class: "material-symbols-outlined"
          }, " error ", -1)), _createTextVNode(_toDisplayString(err.$message), 1)]);
        }), 128))], 2), _createElementVNode("div", _hoisted_6, [_createElementVNode("button", {
          class: "btn btn-primary px-4 py-3 mt-md-4 mt-0",
          onClick: updateTravelsDetails,
          disabled: _unref(loading)
        }, [_unref(loading) ? (_openBlock(), _createElementBlock("span", _hoisted_8)) : _createCommentVNode("", true), _cache[4] || (_cache[4] = _createTextVNode(" Save changes "))], 8, _hoisted_7)])])])])])]),
        _: 1
      });
    };
  }
};