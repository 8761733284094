import { toRaw } from "vue";
import {STATE_STATUSES} from "@/helpers/constants";

export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  setUserOrder(state, payload) {
    state.userOrder = payload;
  },
  setUserDashboard(state, payload) {
    state.userDashboard = payload;
  },

  resetsetUserDashboard(state, payload) {
    state.userDashboard = null;
  },

  //  details dashboard
  extendPolicyPlanDetail(state, payload) {
    state.extendPolicyDetail = payload;
  },
  editExtentPolicy(state, payload) {
    let { extendPolicyDetail } = state;

    state.extendPolicyDetail = { ...toRaw(extendPolicyDetail), ...payload };
  },
  updatebeneficieryInfo(state, payload) {
    let { extendPolicyDetail } = state;

    state.extendPolicyDetail = { ...toRaw(extendPolicyDetail), ...payload };
  },

  setFileLoader(state, payload) {
    state.fileLoader = payload;
  },

  resetDashboard(state,payload){
    state.userDashboard = null;
    state.extendPolicyDetail = null;
    state.status = STATE_STATUSES.INIT;
  }
};
