/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'acute-onset-emergency-of-pre-existing-conditions',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Acute Onset Emergency of Pre-Existing Conditions",
        meta: [{
          name: 'description',
          content: "A sudden and unexpected recurrence of a pre-existing medical condition, which occurs spontaneously and without advance warning, and which would cause a prudent person to seek medical attention. Coverage for the acute onset of pre-existing conditions exists in some plans for persons below a certain age, typically 70 and under. In the event of an acute onset of pre-existing conditions, treatment must be sought within 24 hours. Additionally, such an onset must have occurred after the effective date of the policy. The acute onset of pre-existing condition benefit does not cover conditions which gradually worsen over time. Due to the highly specific nature of pre-existing conditions, the applicability of an acute onset of pre-existing condition benefit is made by a provider's claims department only after a claim has been filed. This is an important feature to include in your policy if you suffer from any preexisting conditions."
        }, {
          property: 'og:description',
          content: "A sudden and unexpected recurrence of a pre-existing medical condition, which occurs spontaneously and without advance warning, and which would cause a prudent person to seek medical attention. Coverage for the acute onset of pre-existing conditions exists in some plans for persons below a certain age, typically 70 and under. In the event of an acute onset of pre-existing conditions, treatment must be sought within 24 hours. Additionally, such an onset must have occurred after the effective date of the policy. The acute onset of pre-existing condition benefit does not cover conditions which gradually worsen over time. Due to the highly specific nature of pre-existing conditions, the applicability of an acute onset of pre-existing condition benefit is made by a provider's claims department only after a claim has been filed. This is an important feature to include in your policy if you suffer from any preexisting conditions."
        }, {
          property: 'og:title',
          content: "Acute Onset Emergency of Pre-Existing Conditions"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/acute-onset-emergency-of-pre-existing-conditions`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/acute-onset-emergency-of-pre-existing-conditions`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Acute Onset Emergency of Pre-Existing Conditions | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "A sudden and unexpected recurrence of a pre-existing medical condition, which occurs spontaneously and without advance warning, and which would cause a prudent person to seek medical attention. Coverage for the acute onset of pre-existing conditions exists in some plans for persons below a certain age, typically 70 and under. In the event of an acute onset of pre-existing conditions, treatment must be sought within 24 hours. Additionally, such an onset must have occurred after the effective date of the policy. The acute onset of pre-existing condition benefit does not cover conditions which gradually worsen over time. Due to the highly specific nature of pre-existing conditions, the applicability of an acute onset of pre-existing condition benefit is made by a provider's claims department only after a claim has been filed. This is an important feature to include in your policy if you suffer from any preexisting conditions."
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/acute-onset-emergency-of-pre-existing-conditions`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Acute Onset Emergency of Pre-Existing Conditions\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"A sudden and unexpected recurrence of a pre-existing medical condition, which occurs spontaneously and without advance warning, and which would cause a prudent person to seek medical attention. Coverage for the acute onset of pre-existing conditions exists in some plans for persons below a certain age, typically 70 and under. In the event of an acute onset of pre-existing conditions, treatment must be sought within 24 hours. Additionally, such an onset must have occurred after the effective date of the policy. The acute onset of pre-existing condition benefit does not cover conditions which gradually worsen over time. Due to the highly specific nature of pre-existing conditions, the applicability of an acute onset of pre-existing condition benefit is made by a provider's claims department only after a claim has been filed. This is an important feature to include in your policy if you suffer from any preexisting conditions.\" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Acute Onset Emergency of Pre-Existing Conditions</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">A sudden and unexpected recurrence of a pre-existing medical condition, which occurs spontaneously and without advance warning, and which would cause a prudent person to seek medical attention. Coverage for the acute onset of pre-existing conditions exists in some plans for persons below a certain age, typically 70 and under. In the event of an acute onset of pre-existing conditions, treatment must be sought within 24 hours. Additionally, such an onset must have occurred after the effective date of the policy. The acute onset of pre-existing condition benefit does not cover conditions which gradually worsen over time. Due to the highly specific nature of pre-existing conditions, the applicability of an acute onset of pre-existing condition benefit is made by a provider&#39;s claims department only after a claim has been filed. This is an important feature to include in your policy if you suffer from any preexisting conditions.</div></div></div></div>", 1)])))])])])], 64);
    };
  }
};