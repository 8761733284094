import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/images/bar.svg';
import _imports_1 from '@/assets/img/logo-m.svg';
import _imports_2 from '@/assets/images/customer-portal.svg';
import _imports_3 from '@/assets/images/logout.svg';
const _hoisted_1 = {
  class: "g1g__navbar"
};
const _hoisted_2 = {
  class: "main-ul"
};
const _hoisted_3 = {
  class: "nav__item mobile-view d-none"
};
const _hoisted_4 = {
  class: "d-flex justify-content-between mt-2"
};
const _hoisted_5 = {
  class: "row"
};
const _hoisted_6 = {
  class: "col-lg-4 sub-menu"
};
const _hoisted_7 = {
  class: "list-unstyled"
};
const _hoisted_8 = {
  class: "col-lg-4 sub-menu"
};
const _hoisted_9 = {
  class: "list-unstyled"
};
const _hoisted_10 = {
  class: "col-lg-4 sub-menu"
};
const _hoisted_11 = {
  class: "list-unstyled"
};
const _hoisted_12 = {
  class: "nav__item"
};
const _hoisted_13 = {
  class: "nav__item"
};
const _hoisted_14 = {
  href: "javascript:void(0)",
  class: "nav__link"
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 0,
  class: "nav__item d-none d-sm-block d-md-none"
};
const _hoisted_17 = {
  key: 1,
  class: "nav__item d-none d-sm-block d-md-none"
};
const _hoisted_18 = {
  key: 2,
  class: "nav__item d-none d-sm-block d-md-none"
};
const _hoisted_19 = {
  class: "social-icons-mobile"
};
const _hoisted_20 = {
  class: "social-media-icons icons-align"
};
const _hoisted_21 = {
  key: 0,
  class: "mobile-view"
};
const _hoisted_22 = {
  class: "nav__list__left web-view"
};
const _hoisted_23 = {
  key: 0,
  class: "nav-item user-dropdown"
};
const _hoisted_24 = {
  id: "profileImage"
};
const _hoisted_25 = {
  class: "user-name"
};
const _hoisted_26 = {
  key: 0,
  class: "dropdown-menu"
};
const _hoisted_27 = {
  class: "border-bottom user-name-mob"
};
const _hoisted_28 = {
  class: "border-bottom"
};
const _hoisted_29 = {
  key: 1,
  class: "nav__item login-icon"
};
const _hoisted_30 = {
  key: 2,
  class: "nav__item"
};
const _hoisted_31 = {
  key: 0,
  class: "cart-total-items"
};
const _hoisted_32 = {
  key: 1
};
const _hoisted_33 = {
  version: "1.1",
  id: "Layer_1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 24 24",
  height: "35",
  width: "35",
  style: {
    "enable-background": "new 0 0 24 24"
  },
  "xml:space": "preserve"
};
import { onClickOutside } from "@vueuse/core";
import { initFacebook } from "@/plugins/facebook";
import CartProduct from "@/components/cart/cartWrapper.vue";
import { toggleCartModal, isOpenCart } from "@/helpers/modal";
import { onMounted, onUnmounted, ref, toRaw } from "vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { track } from "@/analytics/events";
import { uuid } from "vue-uuid";
export default {
  __name: 'navbar',
  setup(__props) {
    const plansStore = createNamespacedHelpers("plans");
    const dashboardStore = createNamespacedHelpers("dashboard");
    const userStore = createNamespacedHelpers("user");
    const itiraStore = createNamespacedHelpers("itira");
    const {
      itira
    } = itiraStore.useState(["itira"]);
    const {
      cart
    } = plansStore.useState(["cart"]);
    const {
      resetDashboardStore
    } = dashboardStore.useActions(["resetDashboardStore"]);
    const {
      logout
    } = userStore.useActions(["logout"]);
    const {
      user
    } = userStore.useState(["user"]);
    const menuActive = ref(false);
    const isOpen = ref(false);
    const isOpenMegaMenu = ref(false);
    const outsideClose = ref(null);
    const outsideCloseCart = ref(null);
    const outsideCloseNav = ref(null);
    const timeout = ref(null);
    // initFacebook();
    const trackSegmentClick = event => {
      // code for segment
      track(`Static Clickable`, {
        source: `Menubar - ${event}`
      });
    };
    function handleMouseOut() {
      timeout.value = setTimeout(() => {
        isOpenMegaMenu.value = false;
      }, 200); // adjust the delay as needed
    }
    function handleMouseOverForPortal() {
      clearTimeout(timeout.value);
      isOpen.value = true;
    }
    function handleMouseOutForPortal() {
      timeout.value = setTimeout(() => {
        isOpen.value = false;
      }, 200); // adjust the delay as needed
    }
    function handleMouseOver() {
      clearTimeout(timeout.value);
      isOpenMegaMenu.value = true;
    }
    const active = (event = '') => {
      // code for segment
      track(`Static clickable`, {
        source: `Nav Bar - ${event}`
      });
      menuActive.value = !menuActive.value;
    };
    onClickOutside(outsideClose, event => {
      isOpen.value = false;
    });
    onClickOutside(outsideCloseNav, event => {
      isOpenMegaMenu.value = false;
      // menuActive.value= false;
    });
    onClickOutside(outsideCloseCart, event => {
      isOpenCart.value = false;
    });
    const logoutSubmit = async () => {
      await resetDashboardStore();
      await logout();
      isOpen.value = !isOpen.value;
    };
    const scrollToShowButton = ref(false);
    const checkScrollPosition = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // Check if the bottom of the viewport is at position 420px
      scrollToShowButton.value = scrollTop >= 420;
    };
    onMounted(() => {
      window.addEventListener('scroll', checkScrollPosition);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', checkScrollPosition);
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createElementVNode("img", {
        alt: "menu",
        class: "menu",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = $event => active('Menubar Clicked'))
      }), _createVNode(_Transition, null, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass([{
            menu_active: menuActive.value
          }, "mb-response nav__list__right"])
        }, [_createElementVNode("ul", _hoisted_2, [_createElementVNode("li", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_router_link, {
          to: "/"
        }, {
          default: _withCtx(() => [_createElementVNode("img", {
            onClick: _cache[1] || (_cache[1] = $event => active('Menubar Closed')),
            alt: "G1G logo",
            class: "img-fluid",
            width: "60",
            src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/itira-logo-nav.svg"
          })]),
          _: 1
        }), _createElementVNode("button", {
          class: "close-btn btn subtitle-1",
          onClick: _cache[2] || (_cache[2] = $event => active('Menubar Closed'))
        }, _cache[26] || (_cache[26] = [_createElementVNode("span", {
          class: "material-symbols-outlined"
        }, " close ", -1)]))])]), _createElementVNode("li", {
          ref_key: "outsideCloseNav",
          ref: outsideCloseNav,
          onClick: _cache[15] || (_cache[15] = $event => isOpenMegaMenu.value = !isOpenMegaMenu.value),
          class: "nav__item dropdown ktm-mega-menu"
        }, [_cache[42] || (_cache[42] = _createElementVNode("a", {
          class: "nav__link"
        }, "Travel Insurance ", -1)), _createVNode(_Transition, {
          appear: "",
          name: "slide-fade"
        }, {
          default: _withCtx(() => [isOpenMegaMenu.value ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onMouseout: handleMouseOut,
            class: "dropdown-menu mega-menu"
          }, [_createElementVNode("div", {
            class: "container-fluid px-0",
            onMouseover: handleMouseOver
          }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_cache[31] || (_cache[31] = _createElementVNode("h6", {
            class: "d-none font-weight-bold white-text"
          }, "Travel Insurance", -1)), _createElementVNode("ul", _hoisted_7, [_createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/travel-protection-insurance",
            onClick: _cache[3] || (_cache[3] = $event => active('Travel Protection Insurance'))
          }, {
            default: _withCtx(() => _cache[27] || (_cache[27] = [_createTextVNode(" Travel Protection Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/trip-cancellation-for-any-reason",
            onClick: _cache[4] || (_cache[4] = $event => active('Cancel For Any Reason Insurance'))
          }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [_createTextVNode(" Cancel For Any Reason Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/emergency-medical-evacuation-insurance",
            onClick: _cache[5] || (_cache[5] = $event => active('Emergency Medical Evacuation Insurance'))
          }, {
            default: _withCtx(() => _cache[29] || (_cache[29] = [_createTextVNode(" Emergency Medical Evacuation Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/travel-medical-insurance",
            onClick: _cache[6] || (_cache[6] = $event => active('Travel Medical Insurance'))
          }, {
            default: _withCtx(() => _cache[30] || (_cache[30] = [_createTextVNode(" Travel Medical Insurance ")])),
            _: 1
          })])])]), _createElementVNode("div", _hoisted_8, [_cache[36] || (_cache[36] = _createElementVNode("h6", {
            class: "d-none font-weight-bold white-text"
          }, "Travel Medical Insurance", -1)), _createElementVNode("ul", _hoisted_9, [_createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/usa-visitor-health-insurance",
            onClick: _cache[7] || (_cache[7] = $event => active('USA Visitors Health Insurance'))
          }, {
            default: _withCtx(() => _cache[32] || (_cache[32] = [_createTextVNode(" USA Visitors Health Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/schengen-visa-insurance",
            onClick: _cache[8] || (_cache[8] = $event => active('Schengen Visa Insurance'))
          }, {
            default: _withCtx(() => _cache[33] || (_cache[33] = [_createTextVNode(" Schengen Visa Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/adventure-travel-sports-insurance",
            onClick: _cache[9] || (_cache[9] = $event => active('Adventure Sports Insurance'))
          }, {
            default: _withCtx(() => _cache[34] || (_cache[34] = [_createTextVNode(" Adventure Sports Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/student-medical-insurance",
            onClick: _cache[10] || (_cache[10] = $event => active('Student Medical Insurance'))
          }, {
            default: _withCtx(() => _cache[35] || (_cache[35] = [_createTextVNode(" Student Medical Insurance ")])),
            _: 1
          })])])]), _createElementVNode("div", _hoisted_10, [_cache[41] || (_cache[41] = _createElementVNode("h6", {
            class: "d-none font-weight-bold white-text"
          }, "International Health Insurance", -1)), _createElementVNode("ul", _hoisted_11, [_createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/nomad-insurance",
            onClick: _cache[11] || (_cache[11] = $event => active('Nomad Insurance'))
          }, {
            default: _withCtx(() => _cache[37] || (_cache[37] = [_createTextVNode(" Nomad Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/expat-insurance",
            onClick: _cache[12] || (_cache[12] = $event => active('Expat Insurance'))
          }, {
            default: _withCtx(() => _cache[38] || (_cache[38] = [_createTextVNode(" Expat Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/cruise-insurance",
            onClick: _cache[13] || (_cache[13] = $event => active('Cruise Insurance'))
          }, {
            default: _withCtx(() => _cache[39] || (_cache[39] = [_createTextVNode(" Cruise Insurance ")])),
            _: 1
          })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
            class: "menu-item",
            to: "/visa-insurance",
            onClick: _cache[14] || (_cache[14] = $event => active('Visa Insurance'))
          }, {
            default: _withCtx(() => _cache[40] || (_cache[40] = [_createTextVNode(" Visa Insurance ")])),
            _: 1
          })])])])])], 32)], 32)) : _createCommentVNode("", true)]),
          _: 1
        })], 512), _createElementVNode("li", _hoisted_12, [_createVNode(_component_router_link, {
          class: "nav__link",
          to: "/about-us",
          onClick: _cache[16] || (_cache[16] = $event => active('About'))
        }, {
          default: _withCtx(() => _cache[43] || (_cache[43] = [_createTextVNode("About ")])),
          _: 1
        })]), _createElementVNode("li", _hoisted_13, [_createVNode(_component_router_link, {
          class: "nav__link",
          to: "/faq",
          onClick: _cache[17] || (_cache[17] = $event => active('Help'))
        }, {
          default: _withCtx(() => _cache[44] || (_cache[44] = [_createTextVNode("Help ")])),
          _: 1
        })]), _createElementVNode("li", {
          class: "nav__item d-none d-sm-block d-md-none",
          onClick: _cache[18] || (_cache[18] = $event => _unref(toggleCartModal)())
        }, [_createElementVNode("a", _hoisted_14, [_cache[45] || (_cache[45] = _createTextVNode("My Cart ")), _unref(cart).length ? (_openBlock(), _createElementBlock("span", _hoisted_15, "(" + _toDisplayString(_unref(cart).length) + ")", 1)) : _createCommentVNode("", true)])]), _unref(user) === null ? (_openBlock(), _createElementBlock("li", _hoisted_16, [_createVNode(_component_router_link, {
          class: "nav__link",
          to: "/login",
          onClick: _cache[19] || (_cache[19] = $event => active('About'))
        }, {
          default: _withCtx(() => _cache[46] || (_cache[46] = [_createTextVNode("My Account ")])),
          _: 1
        })])) : (_openBlock(), _createElementBlock("li", _hoisted_17, [_createVNode(_component_router_link, {
          class: "nav__link",
          to: "/dashboard/extend-policy-plans",
          onClick: _cache[20] || (_cache[20] = $event => active('About'))
        }, {
          default: _withCtx(() => _cache[47] || (_cache[47] = [_createTextVNode("My Dashboard ")])),
          _: 1
        })])), _unref(user) !== null ? (_openBlock(), _createElementBlock("li", _hoisted_18, [_createElementVNode("a", {
          href: "javascript:void(0)",
          class: "nav__link",
          onClick: logoutSubmit
        }, "Logout ")])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_cache[50] || (_cache[50] = _createElementVNode("a", {
          href: "https://youtube.com/G1G_Travel",
          target: "_blank"
        }, [_createElementVNode("svg", {
          fill: "#ffffff",
          height: "30px",
          viewBox: "0 0 30 30",
          width: "30px",
          xmlns: "http://www.w3.org/2000/svg"
        }, [_createElementVNode("path", {
          d: "M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"
        })])], -1)), _createElementVNode("a", {
          onClick: _cache[21] || (_cache[21] = $event => trackSegmentClick('Instagram Link')),
          href: "https://www.instagram.com/g1g_travel/",
          target: "_blank"
        }, _cache[48] || (_cache[48] = [_createElementVNode("svg", {
          fill: "#ffffff",
          height: "28px",
          viewBox: "0 0 24 24",
          width: "30px",
          xmlns: "http://www.w3.org/2000/svg"
        }, [_createElementVNode("path", {
          d: "M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
        })], -1)])), _createElementVNode("a", {
          onClick: _cache[22] || (_cache[22] = $event => trackSegmentClick('Linkedin Link')),
          href: "https://www.linkedin.com/company/g1g-travel",
          target: "_blank"
        }, _cache[49] || (_cache[49] = [_createElementVNode("svg", {
          fill: "#ffffff",
          width: "22px",
          version: "1.1",
          id: "Layer_1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          viewBox: "0 0 310 310",
          "xml:space": "preserve"
        }, [_createElementVNode("g", {
          id: "XMLID_801_"
        }, [_createElementVNode("path", {
          id: "XMLID_802_",
          d: "M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73\n            C77.16,101.969,74.922,99.73,72.16,99.73z"
        }), _createElementVNode("path", {
          id: "XMLID_803_",
          d: "M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4\n            c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
        }), _createElementVNode("path", {
          id: "XMLID_804_",
          d: "M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599\n            c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319\n            c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995\n            C310,145.43,300.549,94.761,230.454,94.761z"
        })])], -1)]))])])], 2)]),
        _: 1
      }), _createVNode(_component_router_link, {
        to: "/"
      }, {
        default: _withCtx(() => _cache[51] || (_cache[51] = [_createElementVNode("img", {
          alt: "logo",
          class: "nav__logo",
          src: _imports_1
        }, null, -1)])),
        _: 1
      }), _unref(cart).length === 0 && scrollToShowButton.value ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_Transition, {
        appear: "",
        name: "slide-fade"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_link, {
          class: "btn-primary check-prices",
          to: "/itira-welcome"
        }, {
          default: _withCtx(() => _cache[52] || (_cache[52] = [_createTextVNode("Check prices ")])),
          _: 1
        })]),
        _: 1
      })])) : _createCommentVNode("", true), _createElementVNode("ul", _hoisted_22, [_unref(user) != null ? (_openBlock(), _createElementBlock("li", _hoisted_23, [_createElementVNode("div", {
        ref_key: "outsideClose",
        ref: outsideClose,
        class: "half"
      }, [_createElementVNode("div", {
        class: "nav-link",
        onClick: _cache[23] || (_cache[23] = $event => isOpen.value = !isOpen.value)
      }, [_createElementVNode("div", _hoisted_24, _toDisplayString(_unref(user).first_name ? _unref(user).first_name.charAt(0).toUpperCase() : ''), 1), _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(user).first_name ?? ''), 1)]), _createVNode(_Transition, {
        appear: "",
        name: "slide-fade"
      }, {
        default: _withCtx(() => [isOpen.value ? (_openBlock(), _createElementBlock("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createVNode(_component_router_link, {
          to: "/dashboard/extend-policy-plans",
          class: "dropdown-item"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(user).first_name ?? ''), 1)]),
          _: 1
        })]), _createElementVNode("div", _hoisted_28, [_createVNode(_component_router_link, {
          to: "/dashboard/extend-policy-plans",
          class: "dropdown-item"
        }, {
          default: _withCtx(() => _cache[53] || (_cache[53] = [_createElementVNode("img", {
            src: _imports_2,
            alt: "G1G user dashboard"
          }, null, -1), _createTextVNode("Customer portal ")])),
          _: 1
        })]), _createElementVNode("div", null, [_createElementVNode("a", {
          class: "dropdown-item",
          href: "#",
          onClick: logoutSubmit
        }, _cache[54] || (_cache[54] = [_createElementVNode("img", {
          src: _imports_3,
          alt: "G1G user logout"
        }, null, -1), _createTextVNode("Logout")]))])])) : _createCommentVNode("", true)]),
        _: 1
      })], 512)])) : _createCommentVNode("", true), _unref(user) == null ? (_openBlock(), _createElementBlock("li", _hoisted_29, [_createVNode(_component_router_link, {
        class: "nav__link",
        to: "/login"
      }, {
        default: _withCtx(() => _cache[55] || (_cache[55] = [_createTextVNode(" My Account ")])),
        _: 1
      })])) : _createCommentVNode("", true), _unref(cart).length === 0 && scrollToShowButton.value ? (_openBlock(), _createElementBlock("li", _hoisted_30, [_createVNode(_Transition, {
        appear: "",
        name: "slide-fade"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_link, {
          class: "btn-primary check-prices",
          to: "/itira-welcome"
        }, {
          default: _withCtx(() => _cache[56] || (_cache[56] = [_createTextVNode("Check prices ")])),
          _: 1
        })]),
        _: 1
      })])) : _createCommentVNode("", true), _unref(cart).length > 0 ? (_openBlock(), _createElementBlock("li", {
        key: 3,
        class: _normalizeClass(["nav__item shop-cart-icon", {
          cartShow: _unref(isOpenCart)
        }])
      }, [_createElementVNode("button", {
        class: "btn",
        onClick: _cache[24] || (_cache[24] = $event => _unref(toggleCartModal)())
      }, [_unref(cart).length ? (_openBlock(), _createElementBlock("span", _hoisted_31, [_cache[57] || (_cache[57] = _createElementVNode("svg", {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 21",
        height: "34.5",
        width: "34"
      }, [_createElementVNode("g", {
        id: "Group_2"
      }, [_createElementVNode("g", {
        id: "baggage"
      }, [_createElementVNode("path", {
        id: "Path_12",
        d: "M3.5,21c-1.93,0-3.5-1.57-3.5-3.5V7.5c0-1.93,1.57-3.5,3.5-3.5h4.5v-1.5c0-1.38,1.12-2.49,2.5-2.5h3c1.38,0,2.49,1.12,2.5,2.5v1.5h4.5c1.93,0,3.5,1.57,3.5,3.5v10c0,1.93-1.57,3.5-3.5,3.5H3.5Zm17-1c1.38,0,2.5-1.12,2.5-2.5V7.5c0-1.38-1.12-2.5-2.5-2.5h-1.5v15h1.5Zm-1.5,0V5h-2v15h2Zm-2,0V5H8v15h9Zm-9,0V5H4v15h4ZM3.5,5c-1.38,0-2.5,1.12-2.5,2.5v10c0,1.38,1.12,2.5,2.5,2.5h.5V5h-.5Zm11.5-1v-1.5c0-.83-.67-1.5-1.5-1.5h-3c-.83,0-1.49,.67-1.5,1.5v1.5h6Z"
      })])])], -1)), _createElementVNode("span", null, _toDisplayString(_unref(cart).length), 1)])) : (_openBlock(), _createElementBlock("span", _hoisted_32, [(_openBlock(), _createElementBlock("svg", _hoisted_33, _cache[58] || (_cache[58] = [_createElementVNode("g", null, [_createElementVNode("title", null, "baggage"), _createElementVNode("path", {
        d: "M3.5,22.5C1.57,22.5,0,20.93,0,19V9c0-1.93,1.57-3.5,3.5-3.5H8V4c0.004-1.375,1.125-2.496,2.499-2.5H13.5\n                     c1.375,0.004,2.496,1.125,2.5,2.499V5.5h4.5C22.43,5.5,24,7.07,24,9v10c0,1.93-1.57,3.5-3.5,3.5H3.5z M20.5,21.5\n                     c1.378,0,2.5-1.122,2.5-2.5V9c0-1.379-1.122-2.5-2.5-2.5H20v15H20.5z M19,21.5v-15h-2v15H19z M16,21.5v-15H8v15H16z M7,21.5v-15H5\n                     v15H7z M3.5,6.5C2.121,6.5,1,7.621,1,9v10c0,1.378,1.121,2.5,2.5,2.5H4v-15H3.5z M15,5.5V4c-0.002-0.824-0.676-1.497-1.501-1.5\n                     h-2.998C9.676,2.503,9.003,3.176,9,4.001V5.5H15z"
      })], -1)])))]))])], 2)) : _createCommentVNode("", true)]), _createVNode(CartProduct, {
        show: _unref(isOpenCart),
        onOnclose: _cache[25] || (_cache[25] = $event => isOpenCart.value = false)
      }, null, 8, ["show"])]);
    };
  }
};