import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/insurance-icons/G1G__Ic_TRAVEL-INSURANCE.svg';
import _imports_1 from '@/assets/images/insurance-icons/G1G__Ic_VISITOR-INSURANCE.svg';
import _imports_2 from '@/assets/images/insurance-icons/G1G__Ic_Medical-Evacuation.svg';
import _imports_3 from '@/assets/images/insurance-icons/G1G__Ic_Nomad-Insurance.svg';
const _hoisted_1 = {
  class: "px-3"
};
const _hoisted_2 = {
  class: "empty-cart-link"
};
const _hoisted_3 = {
  class: "empty-cart-link"
};
const _hoisted_4 = {
  class: "empty-cart-link"
};
const _hoisted_5 = {
  class: "empty-cart-link"
};
const _hoisted_6 = {
  class: "empty-cart-link"
};
import { useRouter } from "vue-router";
import { isOpenCart } from "@/helpers/modal";
export default {
  __name: 'emptyCart',
  setup(__props) {
    const router = useRouter();
    const redirectInsurance = pageLink => {
      isOpenCart.value = false;
      router.push({
        path: pageLink
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "heading"
      }, [_createElementVNode("h5", null, "Your cart is empty")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => redirectInsurance('/travel-protection-insurance')),
        class: "d-flex align-items-center w-100 btn border-0 p-0"
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("h6", null, "Shop Travel Insurance", -1)]))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = $event => redirectInsurance('/usa-visitor-health-insurance')),
        class: "d-flex align-items-center w-100 btn border-0 p-0"
      }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1), _createElementVNode("h6", null, "Shop Visitors Insurance", -1)]))]), _createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = $event => redirectInsurance('/emergency-medical-evacuation-insurance')),
        class: "d-flex align-items-center w-100 btn border-0 p-0"
      }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1), _createElementVNode("h6", null, "Shop Evacuation Insurance", -1)]))]), _createElementVNode("div", _hoisted_5, [_createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = $event => redirectInsurance('/nomad-insurance')),
        class: "d-flex align-items-center w-100 btn border-0 p-0"
      }, _cache[8] || (_cache[8] = [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1), _createElementVNode("h6", null, "Shop Digital Nomad Coverage", -1)]))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = $event => redirectInsurance('/travel-medical-insurance')),
        class: "d-flex align-items-center w-100 btn border-0 p-0"
      }, _cache[9] || (_cache[9] = [_createElementVNode("img", {
        src: "/G1G__Ic_travel_medical.svg",
        alt: ""
      }, null, -1), _createElementVNode("h6", null, "Shop Travel Medical Insurance", -1)]))])]);
    };
  }
};