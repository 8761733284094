/* unplugin-vue-components disabled */import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "main static-space-top"
};
const _hoisted_2 = {
  class: "custom-container faq-inner mt-4"
};
const _hoisted_3 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 1
};
import { useMeta } from 'vue-meta';
export default {
  __name: 'accident',
  setup(__props) {
    const metaTags = computed(() => {
      return {
        title: "Accident",
        meta: [{
          name: 'description',
          content: "An accident is any unforeseen, unintended incident that results in property or physical damage, injury or death. As far as travel medical insurance is concerned, this could be anything from cutting your hand while cooking to sustaining a minor slip and fall. The most common form of accident occurs in automobiles with 20-30 million people sustaining injuries each year. Additionally, 1.3 million deaths occur annually from auto related accidents making them the most common form of mishap. Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses.Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses. "
        }, {
          property: 'og:description',
          content: "An accident is any unforeseen, unintended incident that results in property or physical damage, injury or death. As far as travel medical insurance is concerned, this could be anything from cutting your hand while cooking to sustaining a minor slip and fall. The most common form of accident occurs in automobiles with 20-30 million people sustaining injuries each year. Additionally, 1.3 million deaths occur annually from auto related accidents making them the most common form of mishap. Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses.Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses. "
        }, {
          property: 'og:title',
          content: "Accident"
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/accident`
        }, {
          property: 'og:site_name',
          content: 'G1G'
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:locale',
          content: 'en_US'
        }, {
          property: 'og:url',
          content: `${window.location.origin}/definition/accident`
        }, {
          name: 'twitter:card',
          content: 'summary'
        }, {
          name: 'twitter:site',
          content: '@G1G_Travel'
        }, {
          name: 'twitter:title',
          content: "Accident | G1G Travel & Visitors Insurance"
        }, {
          property: 'twitter:description',
          content: "An accident is any unforeseen, unintended incident that results in property or physical damage, injury or death. As far as travel medical insurance is concerned, this could be anything from cutting your hand while cooking to sustaining a minor slip and fall. The most common form of accident occurs in automobiles with 20-30 million people sustaining injuries each year. Additionally, 1.3 million deaths occur annually from auto related accidents making them the most common form of mishap. Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses.Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses. "
        }],
        link: [{
          rel: 'canonical',
          href: `${window.location.origin}/definition/accident`
        }]
      };
    });
    useMeta(metaTags);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("template", null, [(_openBlock(), _createBlock(_Teleport, {
        to: "head"
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        type: "application/ld+json"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" { \"@context\": \"http://schema.org\", \"@type\": \"DefinitionPage\", \"mainEntity\": { \"@type\": \"Question\", \"name\": \"Accident\", \"acceptedAnswer\": { \"@type\": \"Answer\", \"text\": \"An accident is any unforeseen, unintended incident that results in property or physical damage, injury or death. As far as travel medical insurance is concerned, this could be anything from cutting your hand while cooking to sustaining a minor slip and fall. The most common form of accident occurs in automobiles with 20-30 million people sustaining injuries each year. Additionally, 1.3 million deaths occur annually from auto related accidents making them the most common form of mishap. Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses.Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&D) coverage, to ensure that any such incident doesn't leave you (or your family) with hefty medical expenses. \" } } } ")])),
        _: 1
      }))]))]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "",
        alt: "arrow",
        src: "https://g1gv3.s3.us-west-1.amazonaws.com/frontend-assets/images/arrow-right.svg"
      }, null, -1), _createElementVNode("h5", null, "Back", -1)])), _ctx.loading === true ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createStaticVNode("<div class=\"d-flex\"><div class=\"faq-detail-body P-0\"><h4 class=\"text-capitalize pb-3\">Accident</h4><div class=\"flex-grow-1 flex-shrink-1 content-box content-entry\"><div class=\"content\">An accident is any unforeseen, unintended incident that results in property or physical damage, injury or death. As far as travel medical insurance is concerned, this could be anything from cutting your hand while cooking to sustaining a minor slip and fall. The most common form of accident occurs in automobiles with 20-30 million people sustaining injuries each year. Additionally, 1.3 million deaths occur annually from auto related accidents making them the most common form of mishap. Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&amp;D) coverage, to ensure that any such incident doesn&#39;t leave you (or your family) with hefty medical expenses.Regardless of what type of accident you sustain while traveling abroad, Travel Medical Insurance covers them all. To receive coverage for any accident that might occur while traveling abroad, we recommend policies that include Accidental Death and Dismemberment (AD&amp;D) coverage, to ensure that any such incident doesn&#39;t leave you (or your family) with hefty medical expenses. </div></div></div></div>", 1)])))])])])], 64);
    };
  }
};